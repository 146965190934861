import React, { Fragment, useState, useEffect } from "react";

import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import { Button, Row } from "reactstrap";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import FeedbackAPI from "../../../api/feedback";
import useApi from "../../../hooks/useApi"

function GestureCategoryList(props) {
  //API CALL
  const getFeedbacks = useApi(FeedbackAPI.getFeedbacks);
  const deleteFeedback = useApi(FeedbackAPI.deleteFeedback);
  const restoreFeedback = useApi(FeedbackAPI.restoreFeedback);

  const [feedbacks, setFeedbacks] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);
  function refreshTable() {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Something goes wrong. Error FB-DT01");
        }
        return;
      }

      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        "all=true",
        `page=${page - 1}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];

      return getFeedbacks.request(params.join("&"), accessToken)
        .then((response) => {
          const feedbackData = response.data || [];
          const feedbackList = feedbackData.data.map((feedback) => ({
            ...feedback,
            name: feedback.deleted
              ? `${feedback.name} (deleted)`
              : feedback.name,
            gestureCategory: feedback.gestureCategory
              ? feedback.gestureCategory.name
              : "-",
            controls: (
              <Row>
                {feedback.deleted ? (
                  <Button
                    color="secondary"
                    className="table-control--hover-show"
                    onClick={handleRestore(feedback._id)}
                  >
                    <span className="material-icons align-middle">restore</span>
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    className="table-control--hover-show"
                    onClick={handleDelete(feedback._id)}
                  >
                    <span className="material-icons align-middle">delete</span>
                  </Button>
                )}
              </Row>
            ),
          }));
          setTotalPage(feedbackData.pagination.totalPage);
          setFeedbacks(feedbackList);
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR FB-DT02");
        });
    });
  }

  const handleDelete = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      if (!window.confirm("Are you sure?")) return;
      
      deleteFeedback.request(id, accessToken)
        .then((_) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error FB-0D");
        });
    });
  };

  const handleRestore = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }

      restoreFeedback.request(id, accessToken)
        .then((_) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error AC-0R");
        });
    });
  };

  return (
    <Fragment>
      <h3>User Feedback List</h3>
      <Datatable
        dataset={feedbacks}
        totalPage={totalPage}
        onChange={handleTableChange}
        hover
      >
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        <DatatableColumn field="name" sortable>
          Name
        </DatatableColumn>
        <DatatableColumn field="email" sortable>
          Email
        </DatatableColumn>
        <DatatableColumn field="subject" sortable>
          Subject
        </DatatableColumn>
        <DatatableColumn field="reason" sortable>
          Reason
        </DatatableColumn>
        <DatatableColumn field="controls">&nbsp;</DatatableColumn>
      </Datatable>
    </Fragment>
  );
}

export default GestureCategoryList;
