import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import TagInput from "../../../components/TagInput.jsx";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";

import useApi from "../../../hooks/useApi";
import GestureAPI from "../../../api/gesture";
import GestureCategoryAPI from "../../../api/gestureCategory";
import { useDropzone } from "react-dropzone";

const previewableUrlPattern =
  /data:(image\/(jpeg|png|jpg)|video\/mp4)|http(s?):\/\/.+\.(jpg|jpeg|png|mp4)/;

function SingleGestureEditor(props) {
  //API CALL
  const getOneGesture = useApi(GestureAPI.getOneGesture);
  const updateGesture = useApi(GestureAPI.updateGesture);
  const getGestureCategories = useApi(GestureCategoryAPI.getGestureCategories);

  const { gestureId, onSubmit, onCancel } = props;
  const [updatedGesture, setUpdatedGesture] = useState(false);
  const [gesture, setGesture] = useState({});
  const [labels, setLabels] = useState([]);
  const [fileURL, setFileURL] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [gestureCategories, setGestureCategories] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const history = useHistory();
  const [newGestures, setNewGestures] = useState([]);
  const [key, setKey] = useState(0); // Key for forcing re-render

  const onDrop = useCallback((files) => {
    const existingGesture = newGestures.slice(0);
    for (const file of files) {
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const newGestureData = {
          ...gesture,
          gesture: file,
          labels: labels,
          category: selectedCategory,
          isUploading: false,
          isUploaded: false,
          isUploadError: false,
          uploadErrorMessage: "",
        };

        setFileURL(fileReader.result);
        existingGesture.push(newGestureData);
        setNewGestures(existingGesture);
      };
      fileReader.readAsDataURL(file);
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    refreshAccessToken(async (err, isSuccess) => {
      try {
        if (!isSuccess) {
          history.replace("/signin");
          return;
        }
        const { data } = await getOneGesture.request(
          gestureId.trim(),
          accessToken
        );

        data.labels = data.labels.map((label) => label.name);
        setLabels(data.labels);
        setFileURL(data.fileURL);
        setSelectedCategory(data.category);
        setGesture(data);
        setKey(key + 1); // Increment key to trigger re-render
      } catch (error) {
        console.error("ERR", error);
        alert("ERR");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gestureId]);

  function submit(e) {
    e.preventDefault();
    setUploading(true);

    refreshAccessToken(async (err, isSuccess) => {
      newGestures.length === 0 && newGestures.push(gesture);
      const gestureList = newGestures.slice(0);

      if (!isSuccess) {
        history.replace("/signin");
        return;
      }

      try {
        let i = 0;
        for (const gesture of gestureList) {
          if (gesture.isUploaded) continue;

          gestureList[i].isUploadError = false;

          if (!labels.length) {
            gestureList[i].isUploadError = true;
            gestureList[i].uploadErrorMessage = "Label is empty";
            continue;
          }

          const formData = new FormData();
          if (updatedGesture) {
            formData.append("gesture", gesture.gesture);
          }
          formData.append("category", gesture.category);
          for (const label of labels) {
            formData.append("labels[]", label);
          }

          try {
            await updateGesture.request(
              formData,
              gesture._id.trim(),
              accessToken
            );

            gestureList[i].isUploaded = true;
            onSubmit();
          } catch (err) {
            console.error(err);
            gestureList[i].isUploadError = true;
            if (err.response) {
              gestureList[i].uploadErrorMessage = err.response.data;
            } else {
              gestureList[i].uploadErrorMessage = "Something went wrong";
            }
            setNewGestures(gestureList);
          }
          i++;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setUploading(false);
      }
    });
  }

  useEffect(() => {
    if (!updatedGesture) {
      setNewGestures([]);
      setFileURL(gesture.fileURL);
      setKey(key + 1); // Increment key to trigger re-render
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedGesture]);

  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        alert("Error occured. ERR RT-01");
        return;
      }

      getGestureCategories
        .request("pageSize=999&all=true", accessToken)
        .then(({ data: { data } }) => setGestureCategories(data))
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR GC-02");
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gesture-editor-wrapper my-2">
      <form onSubmit={submit} onReset={onCancel} disabled={isUploading}>
        {`Video on ${updatedGesture ? "Local" : "Server"} `}
        {updatedGesture && newGestures.length === 0 && (
          <Card className="p-3 d-flex justify-content-center align-items-center">
            <div {...getRootProps()} className="video-dropzone">
              <input
                type="file"
                {...getInputProps()}
                accept=".mp4, .jpg, .png, .mkv, .mov, .jpeg"
              />
              <p className="video-dropzone__prompt">
                {isDragActive
                  ? "Drop files here"
                  : "Drag and drop files, or click here"}
              </p>
            </div>{" "}
            <Button
              color="primary position-absolute"
              style={{ maxWidth: "120px", right: "20px", bottom: "20px" }}
              onClick={() => setUpdatedGesture(false)}
            >
              Cancel
            </Button>
          </Card>
        )}
        {!updatedGesture || newGestures.length > 0 ? (
          <Card
            className="p-0"
            style={{
              marginBottom: "2rem",
            }}
          >
            <CardBody className="p-2 m-0">
              {(previewableUrlPattern.test(gesture.fileURL) &&
                ((/(\.|video\/)mp4/g.test(gesture.fileURL) && (
                  <video
                    className="gesture-preview m-0 gesture-preview--video"
                    key={key}
                    controls
                  >
                    <source src={fileURL} type="video/mp4" />
                  </video>
                )) || (
                  <img
                    src={gesture.fileURL}
                    className="gesture-preview m-0 gesture-preview--image"
                    alt=""
                  />
                ))) || (
                <p className="text-center">
                  <i>preview not available for {gesture.fileURL}</i>
                </p>
              )}
            </CardBody>
            <Button
              color="primary position-absolute"
              style={{ maxWidth: "120px", right: "20px", top: "20px" }}
              onClick={() => setUpdatedGesture(!updatedGesture)}
            >
              {!updatedGesture ? "Update" : "Cancel"}
            </Button>
          </Card>
        ) : (
          <></>
        )}
        <FormGroup>
          <Label>File name</Label>
          <Input type="text" disabled value={gesture.fileURL} />
        </FormGroup>
        <FormGroup>
          <Label>Gesture Labels</Label>
          <TagInput
            value={labels || []}
            placeholder="Labels are comma separated (e.g. a,b,c)"
            delimiter={[",", ";"]}
            onChange={(tags) => {
              console.warn("tag", tags);
              setLabels(tags);
            }}
          ></TagInput>
        </FormGroup>
        <FormGroup>
          <Label>Gesture Category</Label>
          <Input
            type="select"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
          >
            {gestureCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        {gesture.isUploadError && (
          <div className="alert alert-danger">
            <h5>There's an error when uploading {gesture.labels[0]}</h5>
            <p>{gesture.uploadErrorMessage}</p>
          </div>
        )}
        {gesture.isUploaded && (
          <div className="alert alert-success">
            {gesture.labels[0]} successfully uploaded
          </div>
        )}
        <Row>
          <Col>
            <Button type="submit" color="primary" block>
              Save
            </Button>
          </Col>
          <Col>
            <Button type="reset" color="secondary" outline block>
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default SingleGestureEditor;
