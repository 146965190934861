/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback } from "react";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import { useDropzone } from "react-dropzone";
import { FormGroup, Input, Label, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import useApi from "../../../hooks/useApi";
import GestureJktAPI from "../../../api/gestureJkt";
import GestureCategoryAPI from "../../../api/gestureCategory";
import TagInput from "../../../components/TagInput";

import "./GestureJktEditor.css";

const previewableUrlPattern =
  /data:(image\/(jpeg|png|jpg)|video\/mp4)|http(s?):\/\/.+\.(jpg|jpeg|png|mp4)/;

function GestureJktUploader(props) {
  // API CALL
  const getGestureCategories = useApi(GestureCategoryAPI.getGestureCategories);
  const createGesture = useApi(GestureJktAPI.createGesture);

  const [gestures, setGestures] = useState([]);
  const [gestureCategories, setGestureCategories] = useState([]);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        alert("Error occured. ERR RT-01");
        return;
      }

      getGestureCategories
        .request("pageSize=999&all=true", accessToken)
        .then(({ data: { data } }) => setGestureCategories(data))
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR GC-02");
        });
    });
  }, []);

  const onDrop = useCallback((files) => {
    const existingGesture = gestures.slice(0);
    for (const file of files) {
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const newGestureData = {
          gesture: file,
          previewURL: fileReader.result,
          label: [],
          tag: [],
          category: gestureCategories[0]._id,
          isUploading: false,
          isUploaded: false,
          isUploadError: false,
          uploadErrorMessage: "",
        };
        existingGesture.push(newGestureData);
        setGestures(existingGesture);
      };
      fileReader.readAsDataURL(file);
    }
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function submit() {
    setUploading(true);
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        history.replace("/signin");
        return;
      }
      const gestureList = gestures.slice(0);
      let i = 0;
      for (const gesture of gestureList) {
        if (gesture.isUploaded) continue;

        gestureList[i].isUploadError = false;

        if (!gesture.category) {
          gestureList[i].isUploadError = true;
          gestureList[i].uploadErrorMessage = "category is empty";
          continue;
        }
        if (!gesture.label.length) {
          gestureList[i].isUploadError = true;
          gestureList[i].uploadErrorMessage = "Label is empty";
          continue;
        }

        const formData = new FormData();
        formData.append("gesture", gesture.gesture);
        formData.append("category", gesture.category);
        for (const tag of gesture.tag) {
          formData.append("tag[]", tag);
        }
        for (const label of gesture.label) {
          formData.append("label[]", label);
        }

        try {
          await createGesture.request(formData, accessToken);
          gestureList[i].isUploaded = true;
        } catch (err) {
          console.error(err);
          gestureList[i].isUploadError = true;
          if (err.response) {
            gestureList[i].uploadErrorMessage = err.response.data;
          } else {
            gestureList[i].uploadErrorMessage = "Something went wrong";
          }
          setGestures(gestureList);
        }
        i++;
      }

      setGestures(gestureList);
      setUploading(false);
    });
  }

  useEffect(() => {
    setUploadedCount(gestures.filter((gesture) => gesture.isUploaded).length);
    if (uploadedCount > 0 && uploadedCount === gestures.length) {
      history.replace("/gesture");
    }
  }, [gestures, uploadedCount, history]);

  return (
    <Fragment>
      <h3>Gesture Uploader</h3>
      {gestures.map((gesture, index) => (
        <Fragment key={index}>
          <div key={index} className="gesture-editor-wrapper my-2">
            {(previewableUrlPattern.test(gesture.previewURL) &&
              ((/(\.|video\/)mp4/g.test(gesture.previewURL) && (
                <video
                  src={gesture.previewURL}
                  className="gesture-preview gesture-preview--video"
                  controls
                ></video>
              )) || (
                <img
                  src={gesture.previewURL}
                  className="gesture-preview gesture-preview--image"
                  alt=""
                />
              ))) || (
              <p className="text-center m-4">
                <i>preview not available for {gesture.gesture.name}</i>
              </p>
            )}
            <FormGroup>
              <Label>File name</Label>
              <Input type="text" disabled value={gesture.gesture.name} />
            </FormGroup>
            <FormGroup>
              <Label>Gesture Labels</Label>
              <TagInput
                placeholder="Labels are comma separated (e.g. a,b,c)"
                delimiter={[",", ";"]}
                value={gesture.label || []}
                onChange={(tags) => {
                  const gestureList = gestures.slice(0);
                  gestureList[index].label = tags;
                  setGestures(gestureList);
                }}
              ></TagInput>
            </FormGroup>
            <FormGroup>
              <Label>Gesture Tags</Label>
              <TagInput
                value={gesture.tag || []}
                onChange={(tags) => {
                  const gestureList = gestures.slice(0);
                  gestureList[index].tag = tags;
                  setGestures(gestureList);
                }}
              ></TagInput>
            </FormGroup>
            <FormGroup>
              <Label>Gesture Category</Label>
              <Input
                type="select"
                value={gesture.category}
                onChange={(e) => {
                  const gestureList = gestures.slice(0);
                  gestureList[index].category = e.target.value;
                  setGestures(gestureList);
                }}
              >
                {gestureCategories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            {gesture.isUploadError && (
              <div className="alert alert-danger">
                <h5>There's an error when uploading {gesture.gesture.name}</h5>
                <p>{gesture.uploadErrorMessage}</p>
              </div>
            )}
            {gesture.isUploaded && (
              <div className="alert alert-success">
                {gesture.gesture.name} successfully uploaded
              </div>
            )}
            <Button
              color="secondary"
              outline
              block
              onClick={(e) => {
                const gestureList = gestures.slice(0);
                setGestures(gestureList);
              }}
            >
              Remove
            </Button>
          </div>
          <hr className="mb-4" />
        </Fragment>
      ))}
      <div {...getRootProps()} className="video-dropzone">
        <input
          type="file"
          {...getInputProps()}
          accept=".mp4, .jpg, .png, .mkv, .mov, .jpeg"
        />
        <p className="video-dropzone__prompt">
          {isDragActive
            ? "Drop files here"
            : "Drag and drop files, or click here"}
        </p>
      </div>
      {gestures.length > 0 && (
        <Button
          type="button"
          className="mb-3"
          color="primary"
          onClick={submit}
          disabled={isUploading}
          block
        >
          {isUploading
            ? `Uploading...`
            : uploadedCount
            ? `${uploadedCount}/${gestures.length} gesture(s) successfully uploaded`
            : `Upload ${gestures.length} gestures`}
        </Button>
      )}
    </Fragment>
  );
}

export default GestureJktUploader;
