import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import FeedbackList from "./FeedbackList";
import FeedbackDetail from "./FeedbackDetail";

function FeedbackIndex() {
  return (
    <Fragment>
      <Route exact path="/feedback/detail/:id">
        <FeedbackDetail />
      </Route>
      <Route exact path="/feedback">
        <FeedbackList />
      </Route>
    </Fragment>
  );
}

export default FeedbackIndex;
