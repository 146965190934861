import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { isAuthenticated } from "../../auth/AuthCheck.js";

function ProtectedRoute(props) {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  window.exampleChildren = props.children;
  return (
    <Route {...props}>
      {isAuthenticated() ? (
        props.children
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from },
          }}
        ></Redirect>
      )}
    </Route>
  );
}

export default ProtectedRoute;
