import axios from "axios";
import { baseURL } from "../config";


const getQuizs = (params, accessToken) => {
  const resourceUrl = new URL("quiz?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOneQuiz = (id, accessToken) => {
  const resourceUrl = new URL("quiz/by/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createQuestion = (formData, accessToken) => {
  const resourceUrl = new URL("question", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const updateQuestion = (formData, quizId, accessToken) => {
  const resourceUrl = new URL("question/" + quizId.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const createQuiz = (formData, categoryId, accessToken) => {
  const resourceUrl = new URL("quiz/" + categoryId.trim(), baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const updateQuiz = (formData, id, accessToken) => {
  const resourceUrl = new URL("quiz/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteQuiz = (id, accessToken) => {
  const resourceUrl = new URL(`quiz/by/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const activatedQuiz = (id, status, accessToken) => {
  const resourceUrl = new URL(`quiz/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const restoreQuiz = (id, accessToken) => {
  const resourceUrl = new URL(`quiz/by/${id}/restore`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

export default {
  getQuizs,
  createQuestion,
  updateQuestion,
  createQuiz,
  updateQuiz,
  deleteQuiz,
  activatedQuiz,
  restoreQuiz,
  getOneQuiz
};
