//export const baseURL = "http://localhost:3000/";
const environment = process.env.NODE_ENV?.toLowerCase().trim();
const isDev = environment === "development";

// const isDev = true;
export const baseURL = "https://services.hearme.id/";
// isDev
//   ? "http://k8s-developm-hearmein-d773f9d8c9-2036980868.ap-southeast-1.elb.amazonaws.com/"
// : "https://services.hearme.id/";
export const authURL = "https://auth-services.hearme.id/";
// isDev
//   ? "http://dev-instance.hearme.id:3000/"
// : "https://auth-services.hearme.id/";

export const NotificationTypes = [
  ["Gesture", "Gesture"],
  ["GestureCategory", "Category Gesture"],
  ["News", "Berita"],
  ["Content", "Artikel"],
  ["Update", "Update"],
];

export const config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};
