import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import SingleGestureEditor from "./SingleGestureJktEditor";
import NotificationForm from "../../../components/NotificationForm";
import useApi from "../../../hooks/useApi";
import GestureJktAPI from "../../../api/gestureJkt";
import GestureCategoryAPI from "../../../api/gestureCategory";

function GestureJktList() {
  // API Call
  const getGestures = useApi(GestureJktAPI.getGestures);
  const deleteGesture = useApi(GestureJktAPI.deleteGesture);
  const activatedGesture = useApi(GestureJktAPI.activatedGesture);
  const getGestureCategories = useApi(GestureCategoryAPI.getGestureCategories);

  const [gestures, setGestures] = useState([]);
  const [indexGesture, setIndexGesture] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [gestureCategories, setGestureCategories] = useState([]);
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const toggleNotificationModal = () =>
    setNotificationModalShow(!notificationModalShow);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({ isActive: "all" });
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGesture, setSelectedGesture] = useState(null);
  const [selectedGestureNotifier, setSelectedGestureNotifier] = useState(null);

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  function selectGesture(gestureData) {
    const categoryId = gestureData.category._id;
    const labels = gestureData.labels.map((label) => label.name);
    setSelectedGesture({
      ...gestureData,
      category: categoryId,
      tags: [],
      labels,
    });
  }

  const toggleActive = (id, isActive) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        alert("Error occured, ERR RTOKEN-00");
        return;
      }

      activatedGesture
        .request(id, isActive ? "deactivate" : "activate", accessToken)
        .then((_) => refreshTable())
        .catch((error) => {
          console.error(error);
          alert("Something wrong happened, Error GC-0A");
        });
    });
  };

  const handleDelete = (gesture) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return alert("Error occured, ERR RTOKEN-00");
      }

      const confirmed = window.confirm(
        `Delete ${
          gesture.labels[0].name || "this gesture"
        }? This action is not reversible.`
      );
      if (!confirmed) return;

      deleteGesture
        .request(gesture._id, accessToken)
        .then((_) => refreshTable())
        .catch((error) => {
          console.error(error);
          alert("Something Went Wrong, Err GC-0D");
        });
    });
  };

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);

  function refreshTable() {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }

      refreshAccessToken((err, isSuccess) => {
        if (!isSuccess) {
          if (err) {
            console.error(err);
          }
          return;
        }

        getGestureCategories
          .request("pageSize=999&all=true", accessToken)
          .then(({ data: { data } }) => setGestureCategories(data))
          .catch((err) => {
            console.error(err.message);
            alert("Error occured, ERR GC-02");
          });
      });

      //SET PARAMS AND QUERTY
      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page - 1}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];

      getGestures.request(params.join("&"), accessToken).then((response) => {
        const gestureDatas = response.data.data.map((data, index) => {
          const labelNames = data.labels.map((label) => label.name + "");
          const displayedLabel =
            labelNames.slice(0, 2).join(", ") +
            (labelNames.length ? ` (${labelNames.length} more)` : "");

          return {
            ...data,
            preview: (
              <img
                src={data.previewURL}
                alt=""
                className="img-preview-thumbnail"
              />
            ),
            "labels.0.name": displayedLabel,
            category: (data.category || {}).name || <i>no category</i>,
            isActive: data.isActive ? (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  done
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(data._id, data.isActive)}
                >
                  Deactivate
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  clear
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(data._id, data.isActive)}
                >
                  Activate
                </Button>
              </Fragment>
            ),
            actions: (
              <Fragment>
                <ButtonGroup>
                  <a
                    href="#selectedGesture"
                    className="btn btn-outline-primary table-control--hover-show"
                    disabled={
                      selectedGesture && selectedGesture._id === data._id
                    }
                    onClick={() => {
                      selectGesture(data);
                    }}
                  >
                    <span className="material-icons">edit</span>
                  </a>
                  <Button
                    color="success"
                    className="table-control--hover-show"
                    onClick={() => {
                      refreshAccessToken((err, isSuccess) => {
                        if (!isSuccess) {
                          if (err) console.error(err);
                          alert("Failed to submit notification request");
                          return;
                        }
                        setNotificationModalShow(true);
                        setSelectedGestureNotifier(data);
                        setIndexGesture(index);
                      });
                    }}
                  >
                    <span className="material-icons">campaign</span>
                  </Button>
                  <Button
                    color="danger"
                    outline
                    className="table-control--hover-show"
                    onClick={handleDelete(data)}
                  >
                    <span className="material-icons">delete</span>
                  </Button>
                </ButtonGroup>
              </Fragment>
            ),
          };
        });

        setGestures(gestureDatas);
        setTotalPage(response.data.pagination.totalPage || 1);
      });
    });
  }
  return (
    <Fragment>
      {/* Broadcast Modal */}
      <Modal isOpen={notificationModalShow} toggle={toggleNotificationModal}>
        <ModalHeader toggle={toggleNotificationModal}>Notify User</ModalHeader>
        <ModalBody>
          <p className="text-muted">Notify user about this gesture</p>
          {selectedGestureNotifier && (
            <NotificationForm
              onSubmit={() => setNotificationModalShow(false)}
              gesture={selectedGestureNotifier}
              index={indexGesture}
              page={page}
            ></NotificationForm>
          )}
        </ModalBody>
      </Modal>
      <h3>Gesture List</h3>
      <Link to="/gesture/add" className="btn btn-primary">
        Add new
      </Link>
      <div id="selectedGesture"></div>
      {selectedGesture && (
        <SingleGestureEditor
          gestureId={selectedGesture._id}
          onSubmit={() => setSelectedGesture(null)}
          onCancel={() => setSelectedGesture(null)}
        ></SingleGestureEditor>
      )}
      <Datatable
        dataset={gestures}
        onChange={handleTableChange}
        totalPage={totalPage}
        hover
      >
        <DatatableFilter
          field="labels.name"
          type="text"
          label="Labels filter"
        ></DatatableFilter>
        <DatatableFilter field="isActive" type="select" label="Active">
          <option value={true}>Active</option>
          <option value={false}>Inactive</option>
          <option value="all">All</option>
        </DatatableFilter>
        <DatatableFilter field="category" type="select" label="Category">
          <option value="">All</option>
          {gestureCategories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </DatatableFilter>
        <DatatableColumn field="preview">Preview</DatatableColumn>
        <DatatableColumn field="labels.0.name" sortable>
          Label
        </DatatableColumn>
        <DatatableColumn field="isActive">Active</DatatableColumn>
        <DatatableColumn field="category">Category</DatatableColumn>
        <DatatableColumn field="actions">&nbsp;</DatatableColumn>
      </Datatable>
    </Fragment>
  );
}

export default GestureJktList;
