import React, { useState, useEffect } from "react";
import { Input, FormGroup, Button, Label, Row, Col } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import axios from "axios";
import { baseURL } from "../../../config";
import ImageDropzone from "../../../components/ImageDropzone";
import "./GestureColaburationEditor.css";

function GestureCategoryEditor(props) {
  const [categoryIcon, setCategoryIcon] = useState(null);
  const [categoryIconURL, setCategoryIconURL] = useState("");
  const [categoryCover, setCategoryCover] = useState(null);
  const [categoryCoverURL, setCategoryCoverURL] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [isActive, setActive] = useState(false);
  const [isPublic, _] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const { onSubmit } = props;

  useEffect(() => {
    if (!id) return;
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Error occured. ERR-GCE01");
        }
        return;
      }
      const resourceURL = new URL(`gestureCategory/${id}`, baseURL);
      axios
        .get(resourceURL, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          const { name, description, isActive, iconURL, coverURL } = response.data;
          setCategoryName(name);
          setCategoryDescription(description);
          setCategoryIconURL(iconURL);
          setCategoryCoverURL(coverURL);
          setActive(isActive);
        })
        .catch((err) => {
          console.error(err);
          alert("Error Occured, ERR-GCE02");
        });
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        alert("ERR-GCE03");
        return;
      }
      const resourceURL = new URL(
        id ? `gestureCategory/${id}` : "gestureCategory",
        baseURL
      );
      const data = new FormData();
      data.append("icon", categoryIcon);
      data.append("name", categoryName);
      data.append("cover", categoryCover);
      data.append("description", categoryDescription);
      data.append("isActive", isActive);
      data.append("isPublic", isPublic);
      const opts = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const request = id
        ? axios.put(resourceURL, data, opts)
        : axios.post(resourceURL, data, opts);
      request
        .then((response) => {
          onSubmit ? onSubmit() : history.replace("/gestureCategory");
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured. ERR-GCE04");
        });
    });
  };

  return (
    <div className="gesture-category-wrapper">
      <form
        onReset={(e) => {
          history.replace("/gestureColaburation");
        }}
        onSubmit={handleSubmit}
      >
      <Label>Icon Category</Label>
        <ImageDropzone
          required={!id}
          preview={categoryIconURL}
          onChange={({ file, url }) => {
            setCategoryIcon(file);
            setCategoryIconURL(url);
          }}
        ></ImageDropzone>
        <Label>Cover Category</Label>
        <ImageDropzone
          required={!id}
          preview={categoryCoverURL}
          onChange={({ file, url }) => {
            setCategoryCover(file);
            setCategoryCoverURL(url);
          }}
        ></ImageDropzone>
        <FormGroup className="mt-3">
          <Label>Colaburation Name</Label>
          <Input
            type="text"
            value={categoryName}
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Colaburation Description</Label>
          <Input
            type="textarea"
            value={categoryDescription}
            onChange={(e) => {
              setCategoryDescription(e.target.value);
            }}
            required
          ></Input>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={isActive}
              onChange={(e) => {
                setActive(e.target.checked);
              }}
            />
            Active
          </Label>
        </FormGroup>
        <Row>
          <Col>
            <Button type="submit" className="mt-2" block color="primary">
              Submit Colaburation
            </Button>
          </Col>
          <Col>
            <Button
              outline
              type="reset"
              className="mt-2"
              block
              color="secondary"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default GestureCategoryEditor;
