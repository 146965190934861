/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Input, Form, FormGroup, Label, Button, Col, Spinner } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toast, { Toaster } from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';

import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import QuizAPI from "../../../api/quiz";
import GestureAPI from "../../../api/gesture";
import useApi from "../../../hooks/useApi";

import "./QuestionSort.css";


function GestureSort({ categoryId, draggableId, indexOf, quizId, question } ) {
 // API CALL
  const getGestures = useApi(GestureAPI.getGesturesByCategory);
  const createQuestion = useApi(QuizAPI.createQuestion);
  const updateQuestion = useApi(QuizAPI.updateQuestion);

  const notify = (message) => toast.error(message);

  const [gestures, setGestures] = useState([]);
  const [selectedGestures, setSelectedGestures] = useState({});
  const [options] = useState([0,1, 2, 3]);
  const [answer, setAnswer] = useState();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (question) {
      const isAnswer = question.options.findIndex((option) => option._id === question.answerId);
      setAnswer(isAnswer);
    }

    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        alert("Error occured. GS-001");
        if (err) {
          console.error(err);
        }
      }
      if (categoryId) {
          getGestures.request(categoryId, "pageSize=999&filters[isActive]=true", accessToken)
            .then(async (response) => {
              const gestures = response.data.data;
              const gestureList = await gestures.map((gesture, index) => ({
                ...gesture,
                label: gesture.labels.map((label) => label.name).join(", "),
              }));
              const defaultGestures = {}
              
              const [...data] = await Promise.all(options.map(async (index) => {
                if (!question) {
                  defaultGestures[index] = {
                    option: gestures[0].labels[0].name,
                    id: gestures[0]._id
                  };
                  return defaultGestures[index];
                } else {
                  const dataOptions = question.options;
                  const indexGesture = await gestures.findIndex(g => g.labels[0].name === dataOptions[index].option);

                  defaultGestures[index] = {
                    option: dataOptions[index].option,
                    id: gestures[indexGesture]._id
                  };
                  return defaultGestures[index];
                }
              }));

              return { defaultGestures: Object.assign({}, data), gestureList };
            })
            .then(({ defaultGestures, gestureList }) => {
              setSelectedGestures(defaultGestures);
              setGestures(gestureList);
              setIsLoading(false);
            })
            .catch((err) => {
              console.error(err);
              alert("Error occured. ERR-GCE04");
            });
        }
    })
  }, [categoryId, options, question]);

  function onDragEnd(result) {
    if (!result.destination) return;

    setSelectedGestures({
      ...selectedGestures, 
      [result.source.index]: selectedGestures[result.destination.index],
      [result.destination.index]: selectedGestures[result.source.index],
    })
  }

  const submit = (type) => async () => {
    const options = Object.values(selectedGestures);

    if (answer === undefined) return notify("Please Input Default Answer!");
    if (options.length < 4) return notify("Set Option Must Available");

    const payloadAdd = {
      options,
      optionToAnswer: { 
        index: answer,
        option: options[answer].option
      },
      quizId,
      gestureId: options[answer].id
    }

    const payloadUpdate = {
      options,
      optionToAnswer: { 
        index: answer,
        option: options[answer].option
      },
      index: draggableId,
      gestureId: options[answer].id
    }

    try {
      type === "add" 
        ? await createQuestion.request(payloadAdd, accessToken)
        : await updateQuestion.request(payloadUpdate, quizId, accessToken);

      setSuccess(true);
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  }

  return (
    <>
      {
        isLoading 
        ? <Spinner animation="border" variant="info" />
        : <div className="dragndrop-container d-flex flex-column">  
            <ReactTooltip id='global' aria-haspopup='true' >
              <p>Selected checkbox</p>
              <p>For default answer question</p>
            </ReactTooltip>
            <Toaster
              position="top-center"
              reverseOrder={false}
            />
            <DragDropContext onDragEnd={onDragEnd} className="mb-2">
              <Droppable droppableId={`gestureList-${draggableId}`} key={indexOf} >
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {options.map((gesture, index) => {
                      return (
                        <Draggable
                          key={index}
                          index={index}
                          draggableId={`${draggableId.toString()+ index.toString()}`}
                        >
                          {(provided, snapshot) => (
                            <div
                              className="dragndrop-draggable"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={index}
                              >
                              <Form
                                inline
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  e.target.scrollIntoView();
                                }}
                              >
                                <FormGroup className="mr-5">
                                  <a data-tip data-for='global' href="#" >
                                    <Label> <span className="material-icons">info</span> &nbsp;</Label> 
                                  </a>
                                  <Col>
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          checked={answer === index}
                                          onChange={(e) => {
                                            setAnswer(index);
                                          }}
                                        />{" "}
                                        Set Answer
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="mr-2">Gesture</Label>
                                  <Input
                                    type="select"
                                    value={JSON.stringify(selectedGestures[index])}
                                    required
                                    onChange={(e) => {
                                      const data = JSON.parse(e.target.value);
                                      setSelectedGestures({...selectedGestures, [index]:  data});
                                    }}
                                  >
                                    {gestures.map((gesture, index) => {
                                      const option = {
                                        option: gesture.labels[0].name,
                                        id: gesture._id
                                      }

                                      return (
                                        <option  key={index} value={JSON.stringify(option)}>
                                          {gesture.labels[0].name}
                                        </option>
                                      )

                                    })}
                                  </Input>
                                </FormGroup>
                              </Form>
                            </div>
                          )}
                        </Draggable>
                      )}
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              color="primary"
              size="lg"
              onClick={submit(question ? "update" : "add")}
              disabled={success}
              className="align-self-end my-2 px-10"
              >
              { question ? "Update" : "Save" }
            </Button>
          </div>
      }
    </>
  );
}

export default GestureSort;
