import React, { useState, useEffect } from "react";
import { Input, FormGroup, Button, Label, Row, Col } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import ImageDropzone from "../../../components/ImageDropzone";
import useApi from "../../../hooks/useApi";
import GestureCategoryAPI from '../../../api/gestureCategory';

import "./GestureCategoryEditor.css";

function GestureCategoryEditor(props) {
  //API CALL
  const updateGestureCategory = useApi(GestureCategoryAPI.updateGestureCategory);
  const createGestureCategory = useApi(GestureCategoryAPI.createGestureCategory);
  const getOneGestureCategory= useApi(GestureCategoryAPI.getOneGestureCategory);

  const [categoryIcon, setCategoryIcon] = useState(null);
  const [modifiedCategoryIcon, setModifiedCategoryIcon] = useState(null);
  const [categoryIconURL, setCategoryIconURL] = useState("");
  const [categoryCover, setCategoryCover] = useState(null);
  const [modifiedCategoryCover, setModifiedCategoryCover] = useState(null);
  const [categoryCoverURL, setCategoryCoverURL] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [isActive, setActive] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const { onSubmit } = props;

  const handleSubmitCover = ({ file, url }) => {
    if (id) setModifiedCategoryCover(file);
    else setCategoryCover(file);

    setCategoryCoverURL(url);
  };

  const handleSubmitIcon = ({ file, url }) => {
    if (id) setModifiedCategoryIcon(file, id);
    else setCategoryIcon(file);

    setCategoryIconURL(url);
  };

  useEffect(() => {
    if (!id) return;
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Error occured. ERR-GCE01");
        }
        return;
      }
      
      getOneGestureCategory.request(id, accessToken)
        .then((response) => {
          const { name, description, isActive, iconURL, coverURL } = response.data;
          setCategoryName(name);
          setCategoryDescription(description);
          setCategoryIconURL(iconURL);
          setCategoryCoverURL(coverURL);
          setActive(isActive);
        })
        .catch((err) => {
          console.error(err);
          alert("Error Occured, ERR-GCE02");
        });
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }

        return alert("ERR-GCE03");
      }

      const data = new FormData();
      if (id) {
        if (modifiedCategoryCover) 
          data.append("modifiedCover", modifiedCategoryCover);
        else 
          data.append("coverURL", categoryCoverURL);

        if (modifiedCategoryIcon) 
          data.append("modifiedIcon", modifiedCategoryIcon); 
        else 
          data.append("iconURL", categoryIconURL);
      } else {
        data.append("icon", categoryIcon);
        data.append("cover", categoryCover);
      }
      data.append("name", categoryName);
      data.append("description", categoryDescription);
      data.append("isActive", isActive);


      const request = id
        ? updateGestureCategory.request(data, id, accessToken)
        : createGestureCategory.request(data, accessToken);

      request
        .then((response) => onSubmit ? onSubmit() : history.replace("/gestureCategory"))
        .catch((err) => {
          console.error(err);
          alert("Error occured. ERR-GCE04");
        });
    });
  };

  return (
    <div className="gesture-category-wrapper">
      <form
        onReset={(e) => {
          history.replace("/gestureCategory");
        }}
        onSubmit={handleSubmit}
      >
      <Label>Icon Category</Label>
        <ImageDropzone
          required={!id}
          preview={categoryIconURL}
          onChange={handleSubmitIcon}
        ></ImageDropzone>
        <Label>Cover Category</Label>
        <ImageDropzone
          required={!id}
          preview={categoryCoverURL}
          onChange={handleSubmitCover}
        ></ImageDropzone>
        <FormGroup className="mt-3">
          <Label>Category Name</Label>
          <Input
            type="text"
            value={categoryName}
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Category Description</Label>
          <Input
            type="textarea"
            value={categoryDescription}
            onChange={(e) => {
              setCategoryDescription(e.target.value);
            }}
            required
          ></Input>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={isActive}
              onChange={(e) => {
                setActive(e.target.checked);
              }}
            />
            Active
          </Label>
        </FormGroup>
        <Row>
          <Col>
            <Button type="submit" className="mt-2" block color="primary">
              {id ? " Update" : " Submit" } Category 
            </Button>
          </Col>
          <Col>
            <Button
              outline
              type="reset"
              className="mt-2"
              block
              color="secondary"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default GestureCategoryEditor;
