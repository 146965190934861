import axios from "axios";
import jwt from "jsonwebtoken";
import { authURL } from "../config";

const authBaseURL = authURL;

export let accessToken = window.localStorage.getItem("accessToken");
export let refreshToken = window.localStorage.getItem("refreshToken");
export let lastRefresh = new Date("1970-01-01");

export function isAuthenticated() {
  accessToken = window.localStorage.getItem("accessToken");
  refreshToken = window.localStorage.getItem("refreshToken");

  return accessToken && refreshToken ? true : false;
}

function storeTokens(accTkn, refshTkn) {
  accessToken = accTkn;
  refreshToken = refshTkn;
  window.localStorage.setItem("accessToken", accTkn);
  window.localStorage.setItem("refreshToken", refshTkn);
}

function removeTokens() {
  accessToken = null;
  refreshToken = null;
  window.localStorage.removeItem("accessToken");
  window.localStorage.removeItem("refreshToken");
}

function processToken(token, refreshTkn) {
  const jwtPayload = jwt.decode(token);
  if (jwtPayload.roles) {
    for (const role of jwtPayload.roles) {
      const adminPatternCheck = /admin/i;
      if (adminPatternCheck.test(role.name)) {
        storeTokens(token, refreshTkn);
        break;
      }
    }
  }
}

export function signIn(email, password, cb) {
  const signInURL = new URL("login", authBaseURL);
  // console.log(process.env.NODE_ENV);
  axios
    .post(signInURL.href, {
      email,
      password,
    })
    .then((response) => {
      lastRefresh = new Date();
      processToken(response.data.token, response.data.refreshToken);
      return cb(null, isAuthenticated);
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
      }
      cb(error, false);
    });
}
let refreshTokenTimeout = false;

export function refreshAccessToken(cb) {
  if (new Date() - lastRefresh < 20 * 60 * 1000) {
    console.log("token still fresh (<= 20 minutes)");
    return cb(null, isAuthenticated);
  }
  if (refreshTokenTimeout) clearTimeout(refreshTokenTimeout);
  refreshTokenTimeout = setTimeout(() => {
    const refreshURL = new URL("refreshToken", authBaseURL);
    if (!(refreshToken || window.localStorage.getItem("refreshToken"))) {
      window.location.replace("/signin");
    }
    return axios
      .post(refreshURL.href, {
        token: accessToken || window.localStorage.getItem("accessToken"),
        refreshToken:
          refreshToken || window.localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        lastRefresh = new Date();
        const { token } = response.data;
        processToken(token, response.data.refreshToken);
        return cb(null, isAuthenticated);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          console.error(error.response.data);
        }
        console.log("refresh token error");
        removeTokens();
        //window.location.replace("/signin");
        return cb(error, false);
      });
  }, 250);
}

export function signOut(cb) {
  const signOutURL = new URL("logout", authBaseURL);
  return axios
    .post(signOutURL.href, { token: accessToken })
    .then((response) => {
      removeTokens();
      return cb(null, isAuthenticated);
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        console.error(error.response.data);
      }
      return cb(error, false);
    });
}
