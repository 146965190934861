import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
//import logo from "./logo.svg";

import ProtectedRoute from "./pages/protected/ProtectedRoute.jsx";

import AuthPage from "./pages/Auth";
import Mainframe from "./pages/protected/Mainframe";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signin">
          <AuthPage />
        </Route>
        <ProtectedRoute path="/">
          <Mainframe></Mainframe>
        </ProtectedRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
