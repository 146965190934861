import axios from "axios";
import { baseURL } from "../config";


const getGestureCategories = (params, accessToken) => {
  const resourceUrl = new URL("gestureCategory?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOneGestureCategory = (id, accessToken) => {
  const resourceUrl = new URL("gestureCategory/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createGestureCategory = (formData, accessToken) => {
  const resourceUrl = new URL("gestureCategory", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const updateGestureCategory = (formData, id, accessToken) => {
  const resourceUrl = new URL("gestureCategory/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteGestureCategory = (id, accessToken) => {
  const resourceUrl = new URL(`gestureCategory/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const activatedGestureCategory = (id, status, accessToken) => {
  const resourceUrl = new URL(`gestureCategory/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const restoreGestureCategory = (id, accessToken) => {
  const resourceUrl = new URL(`gestureCategory/${id}/restore`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

export default {
  getGestureCategories,
  createGestureCategory,
  deleteGestureCategory,
  updateGestureCategory,
  activatedGestureCategory,
  restoreGestureCategory,
  getOneGestureCategory
};