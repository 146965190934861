import axios from "axios";
import { baseURL } from "../config";


const getFeedbacks = (params = "", accessToken) => {
  const resourceUrl = new URL("feedback?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOneFeedback = (id, accessToken) => {
  const resourceUrl = new URL("feedback/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getFeedbackAction = (id, params = "", accessToken) => {
  const resourceUrl = new URL(`feedback/${id}/actions?${params}`, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createActionFeedback = (id, formData, accessToken) => {
  const resourceUrl = new URL(`feedback/${id}/actions`, baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteFeedback = (id, accessToken) => {
  const resourceUrl = new URL(`feedback/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const restoreFeedback = (id, accessToken) => {
  const resourceUrl = new URL(`feedback/${id}/restore`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

export default {
  getFeedbacks,
  deleteFeedback,
  createActionFeedback,
  restoreFeedback,
  getOneFeedback,
  getFeedbackAction
};