import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import GestureSort from "./GestureSort";

function GestureSortPage(props) {
  const { categoryId } = useParams();

  return (
    <Fragment>
      <h1>Sort Gesture</h1>
      <GestureSort categoryId={categoryId} />
    </Fragment>
  );
}

export default GestureSortPage;
