import axios from "axios";
import { baseURL } from "../config";


const getLeaderboards = (params = "", accessToken) => {
  const resourceUrl = new URL("leaderboard?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOneLeaderboard = (id, accessToken) => {
  const resourceUrl = new URL("leaderboard/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createLeaderboard = (formData, accessToken) => {
  const resourceUrl = new URL("leaderboard", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const updateLeaderboard = (formData, id, accessToken) => {
  const resourceUrl = new URL("leaderboard/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteLeaderboard = (id, accessToken) => {
  const resourceUrl = new URL(`leaderboard/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const activatedLeaderboard = (id, status, accessToken) => {
  const resourceUrl = new URL(`leaderboard/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const restoreLeaderboard = (id, accessToken) => {
  const resourceUrl = new URL(`leaderboard/${id}/restore`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

export default {
  getLeaderboards,
  createLeaderboard,
  deleteLeaderboard,
  updateLeaderboard,
  activatedLeaderboard,
  restoreLeaderboard,
  getOneLeaderboard
};