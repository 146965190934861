import React, { useState, useEffect, useReducer } from "react";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  // Modal,
  // Container,
  // ModalBody,
  // ModalHeader,
  // ButtonToggle,
} from "reactstrap";
// import { collection, getDocs, query, where } from "firebase/firestore/lite";
// import DatePicker from "react-datepicker";

import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import useApi from "../../../hooks/useApi";
import AuthAPI from "../../../api/auth";

function UserList(props) {
  //API CALL
  const getUsersApi = useApi(AuthAPI.getUsers);
  const getRolesApi = useApi(AuthAPI.getRoles);
  const exportUserDataApi = useApi(AuthAPI.exportUserData);

  const [users, setUsers] = useState([]);
  const [userRoles, setUserRoles] = useState({});
  const [totalPage, setTotalPage] = useState(1);

  const notifyErr = (message) => toast.error(message);
  const notify = (message) => toast.success(message);
  const toastLoad = () => toast.loading("Loading...", { duration: 8000 });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [dateState, dateDispatch] = useReducer(
    (updateState, currentState) => ({ ...updateState, ...currentState }),
    {
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      withDate: false,
    }
  );

  function setParams(usedAllParams = true) {
    const filterQueries = Object.keys(filters)
      .map((key) => `filter[${key}]=${encodeURIComponent(filters[key])}`)
      .join("&");
    const orderQueries = Object.keys(orders)
      .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
      .join("&");
    const params = [
      `page=${page - 1}`,
      `pageSize=${pageSize}`,
      filterQueries,
      orderQueries,
      `q=${encodeURIComponent(searchQuery) || ""}`,
    ];

    return usedAllParams ? params : `${filterQueries}&${orderQueries}`;
  }

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);

  function refreshTable() {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }

      const roles = {};
      const { data: roleResp } = await getRolesApi.request();
      for (const role of roleResp) {
        roles[role._id] = role;
      }
      setUserRoles(roles);

      await Promise.resolve();

      const { data: users } = await getUsersApi.request(
        setParams().join("&"),
        accessToken
      );

      const userList = users.data.map((userData) => ({
        ...userData,
        "profile.picture": (
          <img
            src={userData.profile.picture}
            alt={`${userData.profile.name}'s`}
            style={{
              width: "64px",
              height: "64px",
              objectFit: "cover",
              borderRadius: "100%",
            }}
          />
        ),
        "profile.name": <b>{userData.profile.name}</b>,
        isHearing:
          typeof userData.isHearing !== "boolean"
            ? "Unknown"
            : userData.isHearing
            ? "Hearing"
            : "Deaf",
        roleNames:
          userData.roles.map((roleID) => roles[roleID].name).join(", ") ||
          "User",
        roles: userData.roles.map((roleID_1) => roles[roleID_1]),
        createdAt: new Date(userData.createdAt).toLocaleDateString(),
        updatedAt: new Date(userData.updatedAt).toLocaleDateString(),
      }));
      setTotalPage(users.pagination.totalPage);
      setUsers(userList);
    });
  }

  const [csvDelimiter, setCsvDelimiter] = useState(",");

  function saveStringToFile(content, fileName) {
    const tempA = document.createElement("a");
    tempA.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`
    );
    notify("Data berhasil diunduh");

    tempA.setAttribute("download", fileName);
    tempA.click();
  }

  function exportUserData(e) {
    e.preventDefault();
    // eslint-disable-next-line no-unused-expressions
    refreshAccessToken((err, isSuccess) => {
      const toastId = toastLoad();
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }

      exportUserDataApi
        .request(`delimiter=${csvDelimiter}&${setParams(false)}`, accessToken)
        .then((response) => {
          if (!response.data) {
            return notifyErr("maaf data tidak ditemukan");
          }
          const content = response.data;
          const fileName = `HearMe_Userdata_export_${new Date().getTime()}.csv`;

          saveStringToFile(content, fileName);
        })
        .catch((err) => {
          console.log("ERROR", err);
        })
        .finally(() => toast.dismiss(toastId));
    });
  }

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mx-3">User List</h3>
        <div className="export-control-wrapper">
          <fieldset className="border-1">
            <legend>Data export</legend>
            <Form inline onSubmit={exportUserData}>
              <FormGroup>
                <Label>CSV Delimiter</Label>
                <Input
                  type="select"
                  className="mx-3"
                  value={csvDelimiter}
                  onChange={(e) => setCsvDelimiter(e.target.value)}
                >
                  <option value=",">Comma (,) [default]</option>
                  <option value=";">
                    Semicolon (;) [better for Microsoft Excel]
                  </option>
                </Input>
              </FormGroup>
              <Button type="submit">Export</Button>
            </Form>
          </fieldset>
        </div>
      </div>
      {/* <Link to="/user/edit"></Link> */}
      <Datatable
        dataset={users}
        totalPage={totalPage}
        hover
        onChange={handleTableChange}
        searchable
      >
        <DatatableFilter
          field="isHearing"
          default="all"
          type="select"
          label="Hearing Status"
        >
          <option value="all">All</option>
          <option value={true}>Hearing</option>
          <option value={false}>Deaf</option>
          <option value={JSON.stringify({ $exists: false })}>Unknown</option>
        </DatatableFilter>
        <DatatableFilter
          field="roles"
          default="all"
          type="select"
          label="User Role"
        >
          <option value="all">All</option>
          {Object.keys(userRoles).map((roleId) => (
            <option value={roleId} key={roleId}>
              {userRoles[roleId].name}
            </option>
          ))}
        </DatatableFilter>
        {/* <DatatableColumn field="_id">
          <strong>User ID</strong>
        </DatatableColumn> */}
        <DatatableColumn style={{ width: "64px" }} field="profile.picture">
          Profile Picture
        </DatatableColumn>
        <DatatableColumn field="profile.name" sortable>
          Name
        </DatatableColumn>
        <DatatableColumn field="email" sortable>
          E-Mail Address
        </DatatableColumn>
        <DatatableColumn field="phoneNumber" sortable>
          Phone Number
        </DatatableColumn>
        <DatatableColumn field="isHearing">Hearing/Deaf</DatatableColumn>
        {/* <DatatableColumn field="roleNames">User Roles</DatatableColumn> */}
        <DatatableColumn field="createdAt" sortable>
          Registered at
        </DatatableColumn>
      </Datatable>
    </>
  );
}

export default UserList;
