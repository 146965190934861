import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Table,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
} from "reactstrap";
import "./Datatable.css";

function Datatable(props) {
  const tableColumns = props.children.filter(
    (child) => child.type.name === DatatableColumn.name
  );
  const tableFilters = props.children.filter(
    (child) => child.type.name === DatatableFilter.name
  );
  const [columns, setColumns] = useState(tableColumns.map((col) => col.props));
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState(props.searchQuery || "");
  const [isLoadingData, setLoadingData] = useState(true);

  const { dataset } = props;
  const pageSizes = props.pageSizes || [10, 20, 50, 100];

  useEffect(() => {
    const newOrder = {};
    for (const col of columns) {
      if (col.order) {
        newOrder[col.field] = col.order;
      }
    }
    setOrders(newOrder);
  }, [columns]);

  const sortColumn = (i) => () => {
    if (!columns[i].sortable) return;
    const cols = JSON.parse(JSON.stringify(columns));
    if (!props.multipleSort) {
      for (let x = 0; x < cols.length; x++) {
        if (x === i) continue;
        cols[x].order = false;
      }
    }
    cols[i].order =
      cols[i].order === "asc"
        ? "desc"
        : cols[i].order === "desc"
        ? false
        : "asc";
    //if (!cols[i].order) {
    //const ords = JSON.parse(JSON.stringify(orders));
    //delete ords[cols[i].field];
    //setOrders(ords);
    //} else if (props.multipleSort) {
    //setOrders({ ...orders, [cols[i].field]: cols[i].order });
    //} else {
    //setOrders({ [cols[i].field]: cols[i].order });
    //}
    setColumns(cols);
    setPage(1);
  };

  const { onChange } = props;

  const handleFilterChange = (field) => (e) => {
    let newFilters = JSON.parse(JSON.stringify(filters));
    if (!(e.target.value + "").trim()) {
      delete newFilters[field];
    } else {
      newFilters[field] = e.target.value;
    }
    setFilters(newFilters);
    setPage(1);
  };
  const changeTimeoutMs = 250; // milliseconds
  let changeTimeoutHandler = useRef(null);

  useEffect(() => {
    setLoadingData(true);

    if (changeTimeoutHandler.current !== null) {
      clearTimeout(changeTimeoutHandler.current);
    }

    changeTimeoutHandler.current = setTimeout(() => {
      onChange({
        page,
        pageSize,
        filters,
        orders,
        searchQuery,
      });
      changeTimeoutHandler.current = null;
    }, changeTimeoutMs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, filters, orders, page, pageSize, searchQuery]);

  useEffect(() => {
    setLoadingData(false);
  }, [dataset]);

  window.columns = columns;
  return (
    <div className="datatable-wrapper">
      {props.searchable && (
        <FormGroup>
          <Input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setPage(1);
              setSearchQuery(e.target.value);
            }}
            placeholder="Search"
          ></Input>
        </FormGroup>
      )}
      {tableFilters && (
        <Row className="datatable-wrapper__filter-container">
          {tableFilters.map((filter, i) => (
            <Col
              key={filter.props.field + i}
              sm="12"
              md="6"
              lg="3"
              className="datatable-filter"
            >
              <FormGroup>
                <Label
                  for={`filter-${filter.props.field}}`}
                  className="datatable-filter__label"
                >
                  {filter.props.label}
                </Label>
                {filter.props.type === "text" && (
                  <Input
                    type="text"
                    bsSize="sm"
                    placeholder="Exact value"
                    id={`filter-${filter.props.field}}`}
                    value={
                      filters[filter.props.field] || filter.props.default || ""
                    }
                    onChange={handleFilterChange(filter.props.field)}
                  ></Input>
                )}
                {filter.props.type === "select" && (
                  <Input
                    type="select"
                    bsSize="sm"
                    id={`filter-${filter.props.field}}`}
                    defaultValue={filter.props.default || ""}
                    onChange={handleFilterChange(filter.props.field)}
                  >
                    <option disabled>Select option</option>
                    {filter.props.children}
                  </Input>
                )}
                {filter.props.type === "checkbox" && (
                  <Input
                    type="checkbox"
                    id={`filter-${filter.props.field}}`}
                    checked={filters[filter.props.field] || false}
                    onChange={handleFilterChange(filter.props.field)}
                  ></Input>
                )}
              </FormGroup>
            </Col>
          ))}
        </Row>
      )}
      <div style={{ position: "relative" }}>
        <div className={"loading-overlay" + (isLoadingData ? "" : " d-none")}>
          <p className="m-0 lead">
            <b>
              <em>Loading data...</em>
            </b>
          </p>
        </div>
        <Table
          color={props.color}
          size={props.size}
          hover={props.hover}
          className="datatable"
        >
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  key={column.field}
                  className={`datatable__head-column ${
                    column.sortable ? "datatable__head-column--sortable" : ""
                  }`}
                  style={column.style}
                  onClick={sortColumn(index)}
                >
                  {column.children || column.name || column.field}{" "}
                  {column.sortable && (
                    <span className="material-icons ml-2 datatable__head-column__sort-direction">
                      {column.order === "asc"
                        ? "keyboard_arrow_up"
                        : column.order === "desc"
                        ? "keyboard_arrow_down"
                        : "drag_handle"}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataset.map((data, index) => (
              <tr key={index}>
                {columns.map((col, i) => (
                  <td key={i} style={col.style} className="align-middle">
                    {data[col.field]}
                  </td>
                ))}
              </tr>
            ))}
            {dataset.length === 0 && (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center" }}>
                  <em>There is no data right now</em>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div>
        <Form inline className="justify-content-end">
          <Input
            type="select"
            value={pageSize}
            onChange={(e) => {
              // const oldOffset = pageSize * page;
              // setPage(Math.ceil(oldOffset / e.target.value));
              setPage(1);
              setPageSize(e.target.value);
            }}
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Input>
          <p className="m-0 ml-2 mr-4 text-muted">Items per page</p>
          <ButtonGroup>
            <Button
              type="button"
              color="secondary"
              outline
              size="sm"
              disabled={page === 1}
              onClick={() => {
                setPage(page - 1 || 1);
              }}
            >
              <span className="material-icons align-middle">
                keyboard_arrow_left
              </span>
            </Button>
            <Button color="secondary" type="button" disabled outline>
              {isLoadingData
                ? "Loading..."
                : `Page ${page} / ${props.totalPage || 1}`}
            </Button>
            <Button
              type="button"
              color="secondary"
              outline
              size="sm"
              disabled={page === props.totalPage}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <span className="material-icons align-middle">
                keyboard_arrow_right
              </span>
            </Button>
          </ButtonGroup>
        </Form>
      </div>
    </div>
  );
}

export function DatatableColumn(props) {
  // This is a shadow elements
  return null;
}

export function DatatableFilter(props) {
  // this is a shadow element
  return null;
}
DatatableFilter.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  //options: PropTypes.shape({
  //label: PropTypes.string,
  //value: PropTypes.any,
  //}),
  value: PropTypes.any,
  type: PropTypes.string,
  default: PropTypes.any,
};

export default Datatable;
