import React, { Fragment } from "react";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import "./TagInput.css";

function TagInput(props) {
  const { onChange, value } = props;

  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend" className="tag-list">
        {value.map((tag, i) => (
          <Fragment key={tag + i}>
            <InputGroupText key={tag + i + "tag"}>{tag}</InputGroupText>
            <Button
              type="button"
              onClick={() => {
                const tagList = value.splice(0);
                tagList.splice(i, 1);
                onChange(tagList);
              }}
              key={tag + i}
              className="p-0 mr-2"
            >
              <span className="material-icons align-middle">clear</span>
            </Button>
          </Fragment>
        ))}
      </InputGroupAddon>
      <Input
        type="text"
        placeholder={props.placeholder || ""}
        className="tag-input"
        onBlur={(e) => {
          if (!e.target.value.trim()) return;
          const tagList = value.splice(0);
          tagList.push((e.target.value + "").trim());
          onChange(tagList);
          e.target.value = "";
        }}
        onKeyUp={(e) => {
          if ((props.delimiter || [" ", ",", ";"]).includes(e.key)) {
            if (!e.target.value.trim()) return;
            const tagList = value.splice(0);
            const newTag = e.target.value + "";
            tagList.push(newTag.substring(0, newTag.length - 1).trim());
            e.target.value = "";
            onChange(tagList);
          } else if (e.key === "Backspace") {
            if (e.target.value === "") {
              const tagList = value.splice(0);
              tagList.pop();
              onChange(tagList);
            }
          }
        }}
      ></Input>
    </InputGroup>
  );
}

export default TagInput;
