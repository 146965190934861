import React, { useState, useEffect } from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import { refreshAccessToken } from "../../../auth/AuthCheck";
import useApi from "../../../hooks/useApi";
import GestureAPI from '../../../api/gesture';


function GestureAggregation(props) {
  // API Call
  const getGestureAggregate = useApi(GestureAPI.getGestureAggregate);
  const getGestureHistory = useApi(GestureAPI.getGestureHistory);
  
  const [recentQueries, setRecentQueries] = useState([]);
  const [popularQueries, setPopularQueries] = useState([]);

  function refreshData() {
    refreshAccessToken((err, isSuccess) => {
      if (err) {
        console.error(err);
        alert("Error Occured. ERR-DASH");
      }
      if (!isSuccess) {
        alert("Refresh token failed");
      }

      const accessToken = window.localStorage.getItem("accessToken");

      return getGestureHistory.request("?order[createdAt]=desc", accessToken)
        .then(({ data: response }) => {
          setRecentQueries(response.data);
          return getGestureAggregate.request(accessToken);
        })
        .then(({ data }) => {
          setPopularQueries(data);
        });
    });
  }

  useEffect(() => {
    refreshData();
    const minutelyRefreshInterval = setInterval(refreshData, 60 * 1000);
    return () => clearInterval(minutelyRefreshInterval);
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <h2>Dashboard</h2>
        </Col>
        <Col xs={12} md={6}>
          <h5>Recent Translation Queries</h5>
          <ListGroup>
            {recentQueries.map((query) => (
              <ListGroupItem key={query._id}>
                {query.query}
                <span className="text-muted">
                  {" "}
                  @ {new Date(query.createdAt).toLocaleString("id")}
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col xs={12} md={6}>
          <h5>Popular Queries</h5>
          <ListGroup>
            {popularQueries.slice(0, 10).map((query) => (
              <ListGroupItem key={query._id}>
                {query._id}
                <span className="text-muted"> @ {query.count}&times;</span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default GestureAggregation;
