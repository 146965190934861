import React, { useState, useEffect } from "react";
import { Input, FormGroup, Button, Label, Row, Col } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import { Editor } from "@tinymce/tinymce-react";
import useApi from "../../../hooks/useApi";
import HelpAPI from "../../../api/help";

import "./HelpEditor.css";


function HelpEditor(props) {    
  //API CALL
  const getHelpCategories = useApi(HelpAPI.getHelpCategories);
  const getOneHelp = useApi(HelpAPI.getOneHelp);
  const createHelp = useApi(HelpAPI.deleteHelp);
  const updateHelp = useApi(HelpAPI.updateHelp);
  
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isActive, setActive] = useState(false);
  const [isPublished, setPublished] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [helpCategories, setHelpCategories] = useState([]);

  const { id } = useParams();
  const history = useHistory();
  const { onSubmit } = props;

  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Error occured. ERR-GCE01");
        }
        return;
      }

      getHelpCategories.request("pageSize=100", accessToken)
        .then((response) => {
          setHelpCategories(response.data.data || []);
        })
        .catch((err) => {
          console.error(err);
          alert("Error Occured. ERR-HC001");
        });

      if (!id) return;

      getOneHelp.request(id, accessToken)
        .then((response) => {
          const {
            title,
            body,
            isActive,
            isPublished,
            category,
          } = response.data;
          setTitle(title);
          setBody(body);
          setActive(isActive);
          setPublished(isPublished);
          setSelectedCategory(category._id);
        })
        .catch((err) => {
          console.error(err);
          alert("Error Occured, ERR-GCE02");
        });
    });
  }, [getHelpCategories, getOneHelp, id]);

  const handleEditorChange = (content, editor) => {
    setBody(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        alert("ERR-GCE03");
        return;
      }
      
      const data = {
        title,
        body,
        isActive,
        isPublished,
        category: selectedCategory,
      };

      const request = id
        ? updateHelp.request(data, id, accessToken)
        : createHelp.request(data, accessToken);
      
      request
        .then((response) => {
          onSubmit ? onSubmit() : history.replace("/help");
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured. ERR-GCE04");
        });
    });
  };

  return (
    <div className="gesture-category-wrapper">
      <form
        onReset={(e) => {
          history.replace("/help");
        }}
        onSubmit={handleSubmit}
      >
        <FormGroup className="mt-3">
          <Label>Help Title</Label>
          <Input
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Help Description</Label>
          <Editor
            apiKey="iyk0wbwkghora7b1i9w4b9pjcdxu3kjawvwlq27w8edplmth"
            value={body}
            onEditorChange={handleEditorChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Help Category</Label>
          <Input
            type="select"
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
            value={selectedCategory}
          >
            <option value={null} disabled>
              Select one
            </option>
            {helpCategories.map((category, i) => (
              <option value={category._id}>{category.name}</option>
            ))}
          </Input>
        </FormGroup>
        <Row>
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={isActive}
                  onChange={(e) => {
                    setActive(e.target.checked);
                  }}
                />
                Active
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={isPublished}
                  onChange={(e) => {
                    setPublished(e.target.checked);
                  }}
                />
                Published
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" className="mt-2" block color="primary">
              Submit Help Data
            </Button>
          </Col>
          <Col>
            <Button
              outline
              type="reset"
              className="mt-2"
              block
              color="secondary"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default HelpEditor;
