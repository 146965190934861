import React, { Fragment, useState, useEffect } from "react";
import { Input, FormGroup, Button, Label, Row, Col } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import axios from "axios";
import { baseURL } from "../../../config";
import "./HelpCategoryEditor.css";

function HelpCategoryEditor(props) {
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [isActive, setActive] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const { onSubmit } = props;

  useEffect(() => {
    if (!id) return;
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Error occured. ERR-GCE01");
        }
        return;
      }
      const resourceURL = new URL(`helpCategory/${id}`, baseURL);
      axios
        .get(resourceURL, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          const { name, description, isActive } = response.data;
          setCategoryName(name);
          setCategoryDescription(description);
          setActive(isActive);
        })
        .catch((err) => {
          console.error(err);
          alert("Error Occured, ERR-GCE02");
        });
    });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        alert("ERR-GCE03");
        return;
      }
      const resourceURL = new URL(
        id ? `helpCategory/${id}` : "helpCategory",
        baseURL
      );
      const data = {
        name: categoryName,
        description: categoryDescription,
        isActive,
      };
      const opts = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const request = id
        ? axios.put(resourceURL, data, opts)
        : axios.post(resourceURL, data, opts);
      request
        .then((response) => {
          onSubmit ? onSubmit() : history.replace("/helpCategory");
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured. ERR-GCE04");
        });
    });
  };

  return (
    <div className="gesture-category-wrapper">
      <form
        onReset={(e) => {
          history.replace("/helpCategory");
        }}
        onSubmit={handleSubmit}
      >
        <FormGroup className="mt-3">
          <Label>Category Name</Label>
          <Input
            type="text"
            value={categoryName}
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Category Description</Label>
          <Input
            type="textarea"
            value={categoryDescription}
            onChange={(e) => {
              setCategoryDescription(e.target.value);
            }}
            required
          ></Input>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={isActive}
              onChange={(e) => {
                setActive(e.target.checked);
              }}
            />
            Active
          </Label>
        </FormGroup>
        <Row>
          <Col>
            <Button type="submit" className="mt-2" block color="primary">
              Submit Category
            </Button>
          </Col>
          <Col>
            <Button
              outline
              type="reset"
              className="mt-2"
              block
              color="secondary"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default HelpCategoryEditor;
