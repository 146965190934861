import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import Datatable, { DatatableColumn, DatatableFilter } from "./Datatable";
import { refreshAccessToken, accessToken } from "../auth/AuthCheck";
import { baseURL } from "../config";

function ArticleSelector(props) {
  const [articles, setArticles] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }
  const { selected, onSelected } = props;

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);

  function refreshTable() {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }
      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page - 1}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];
      const resourceUrl = new URL("/article?" + params.join("&"), baseURL);
      const response = await axios
        .get(resourceUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      setTotalPage(response.data.pagination.totalPage);
      const articleDatas = response.data.data.map((article) => ({
        ...article,
        "pages.title": (
          <p className={selected === article._id ? "text-primary" : ""}>
            <b>{article.pages[0].title}</b>{" "}
            {`(${article.pages.length} page)`}
          </p>
        ),
        coverImage: (
          <img
            src={article.pages[0].coverImageURL}
            alt={article.pages[0].title}
            className="img-preview-thumbnail" />
        ),
        isPublished: article.isPublished ? (
          <span className="material-icons">done</span>
        ) : (
          <span className="material-icons">clear</span>
        ),
        isActive: article.isActive ? (
          <span className="material-icons">done</span>
        ) : (
          <span className="material-icons">clear</span>
        ),
        "admin.name": article.admin ? article.admin.name : "n/a",
        controls: (
          <Button
            color="primary"
            disabled={selected === article._id}
            outline={selected === article._id}
            className="table-control--hover-show"
            onClick={() => onSelected(article)}
          >
            {selected === article._id ? "Selected" : "Select"}
          </Button>
        ),
      }));
      setArticles(articleDatas);
    });
  }

  return (
    <Fragment>
      <h5>Article Selector</h5>
      <Datatable
        dataset={articles}
        totalPage={totalPage}
        onChange={handleTableChange}
        searchable
        hover
      >
        <DatatableFilter
          type="select"
          field="isPublished"
          label="Published Status"
        >
          <option value="all">All</option>
          <option value={true}>Published</option>
          <option value={false}>Not published</option>
        </DatatableFilter>
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        {/* <DatatableColumn field="coverImage">Cover Image</DatatableColumn> */}
        <DatatableColumn field="pages.title" sortable>
          Title
        </DatatableColumn>
        <DatatableColumn field="isPublished" sortable>
          Published
        </DatatableColumn>
        <DatatableColumn field="isActive" sortable>
          Active
        </DatatableColumn>
        <DatatableColumn field="admin.name">Author</DatatableColumn>
        <DatatableColumn field="controls">&nbsp;</DatatableColumn>
      </Datatable>
    </Fragment>
  );
}

export default ArticleSelector;
