import React, { Fragment, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { refreshAccessToken } from "../../../auth/AuthCheck";
import FeedbackAPI from "../../../api/feedback";
import useApi from "../../../hooks/useApi"

import { Button, Form, FormGroup, Label, Input, Table } from "reactstrap";

function FeedbackDetail(props) {
  //API CALL
  const getOneFeedback = useApi(FeedbackAPI.getOneFeedback);
  const getFeedbackAction = useApi(FeedbackAPI.getFeedbackAction);
  const createActionFeedback = useApi(FeedbackAPI.createActionFeedback);

  const { id } = useParams();
  const [feedback, setFeedback] = useState(null);
  const [feedbackActions, setFeedbackActions] = useState([]);
  const [newFeedback, setNewFeedback] = useState({ message: "", type: "" });

  function refreshData() {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Something goes wrong. Error FB-DT02");
        }
        return;
      }

      if (id) {
        const accessToken = window.localStorage.getItem("accessToken");

        return getOneFeedback.request(id, accessToken)
          .then(({ data }) => {
            setFeedback(data);
            return getFeedbackAction.request(id, "pageSize=99&orders[cratedAt]=asc", accessToken)
          })
          .then(({ data }) => {
            const feedbackActions = data.data.map(
              (action) => ({
                ...action,
                createdAt: new Date(action.createdAt).toLocaleString("id"),
              })
            );
            setFeedbackActions(feedbackActions);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }
  useEffect(refreshData, []);

  function handleSubmit(e) {
    e.preventDefault();
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Something goes wrong. Error FB-SMB");
        }
        return;
      }
      const accessToken = window.localStorage.getItem("accessToken");

      return createActionFeedback(id, newFeedback, accessToken)
        .then((_) => {
          setNewFeedback({ type: "", message: "" });
          refreshData();
        })
        .catch((error) => {
          console.error(error);
          setNewFeedback({ type: "", message: "" });
          refreshData();
        });
    });
  }

  return (
    <div className="mx-3">
      <h2>Feedback Ticket detail</h2>
      {feedback && (
        <Fragment>
          <Form>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                className="font-weight-bold"
                readOnly
                value={feedback.name}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input type="email" readOnly value={feedback.email} />
            </FormGroup>
            <FormGroup>
              <Label>Category</Label>
              <Input
                type="text"
                readOnly
                value={
                  feedback.gestureCategory ? feedback.gestureCategory.name : "-"
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Subject</Label>
              <Input type="textarea" readOnly value={feedback.subject} />
            </FormGroup>
            <FormGroup>
              <Label>Reason</Label>
              <Input type="textarea" readOnly value={feedback.reason} />
            </FormGroup>
          </Form>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Timestamp</th>
                <th>Status</th>
                <th>Admin</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {feedbackActions.map((action, index) => (
                <tr key={action._id}>
                  <td>{index + 1}</td>
                  <td>{action.createdAt}</td>
                  <td>{action.type}</td>
                  <td>
                    {action.admin.profile.name || action.admin.email} (
                    {action.admin.email})
                  </td>
                  <td>{action.message}</td>
                </tr>
              ))}
              {feedbackActions.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center">
                    <i>There are no actions performed on this feedback, yet</i>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="5">
                  <Form onSubmit={handleSubmit}>
                    <h5>Update Feedback Action</h5>
                    <FormGroup>
                      <Label>Status</Label>
                      <Input
                        type="select"
                        value={newFeedback.type}
                        onChange={(e) =>
                          setNewFeedback({
                            ...newFeedback,
                            type: e.target.value,
                          })
                        }
                      >
                        <option disabled> Select one</option>
                        <option value="open">Open</option>
                        <option value="draft">Draft</option>
                        <option value="in-review">In Review</option>
                        <option value="in-progress">In Progress</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                        <option value="done">Done</option>
                        <option value="re-open">Re-open</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>Message</Label>
                      <Input
                        type="textarea"
                        value={newFeedback.message}
                        onChange={(e) => {
                          setNewFeedback({
                            ...newFeedback,
                            message: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                    <Button type="submit">Update Status</Button>
                    <Button
                      tag={Link}
                      to="/feedback"
                      outline
                      color="secondary"
                      className="mx-2"
                    >
                      Back to Feedback List
                    </Button>
                  </Form>
                </td>
              </tr>
            </tbody>
          </Table>
        </Fragment>
      )}
    </div>
  );
}

export default FeedbackDetail;
