import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  ButtonGroup,
  Button,
} from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import ImageDropzone from "../../../components/ImageDropzone";
import { baseURL } from "../../../config";
import useApi from "../../../hooks/useApi";
import ArticleAPI from "../../../api/article";
import gestureCategoryAPI from "../../../api/gestureCategory";

import "./ArticleEditor.css";

/**
 * Default value
 */
export const TypeArticles = {
  NavigationPlayMe: "navigation-play-me",
  NavigationLearnMe: "navigation-learn-me",
  Link: "link",
  Normal: "normal",
};

function pickFile(multiple = false) {
  return new Promise((resolve, reject) => {
    const filePicker = document.createElement("input");
    filePicker.setAttribute("type", "file");
    if (multiple) {
      filePicker.setAttribute("multiple", "multiple");
    }
    filePicker.onchange = (e) => {
      if (multiple) {
        return resolve(e.target.files);
      } else {
        return resolve(e.target.files[0]);
      }
    };
    filePicker.onError = (e) => {
      console.error(e);
      return reject(e);
    };
    filePicker.click();
  });
}

function ArticleEditor(props) {
  // API CALL
  const getOneArticle = useApi(ArticleAPI.getOneArticle);
  const getArticleCategories = useApi(ArticleAPI.getArticleCategories);
  const updateArticle = useApi(ArticleAPI.updateArticle);
  const createArticle = useApi(ArticleAPI.createArticle);
  const getGestureCategories = useApi(gestureCategoryAPI.getGestureCategories);
  
  const { id } = useParams();
  const history = useHistory();

  function generateTempId() {
    return Math.round(Math.random() * 99999).toString(16);
  }

  const [pages, setPages] = useState([
    {
      _id: generateTempId(),
      title: "",
      body: "",
      coverImage: null,
      coverImageURL: "",
    },
  ]);

  const [card, setCard] = useState(
    {
      title: "",
      body: "",
      cardImageURL: "",
    },
  );
  const [mountedGesture, setMountedGesture] = useState(false);
  const [cardImage, setCardImage] = useState(null);
  const [modifiedCard, setModifiedCard] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [gestureCategories, setGestureCategories] = useState([]);
  const [isPublished, setPublished] = useState(false);
  const [isActive, setActive] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [targetUrl, setTargetUrl] = useState("");
  const [textButton, setTextButton] = useState("");
  const [targetId, setTargetId] = useState("");

  const [modifiedCover, setModifiedCover] = useState([]);
  
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    refreshAccessToken((error, isSuccess) => {
      if (!isSuccess) {
        if (error) console.error(error);
        return;
      }

      getArticleCategories
        .request()
          .then((response) => {
            setCategories(response.data.data);
            if (id) return getOneArticle.request(id);
            return Promise.resolve(false);
          })
          .then((response) => {
            if (response) {
              if (response.data) {
                const {
                  pages,
                  isPublished,
                  isActive,
                  articleCategory,
                  card
                } = response.data;
                setPages(pages || []);
                setCard(card || {})
                setPublished(isPublished || false);
                setActive(isActive || false);
                setSelectedCategory(articleCategory._id || "");
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
    });
  }, []);

  useEffect(() => {
    if (!mountedGesture && (TypeArticles[selectedType] === TypeArticles.NavigationLearnMe || TypeArticles[selectedType] === TypeArticles.NavigationPlayMe)) {
      getGestureCategories
      .request()
        .then((response) => {
          setMountedGesture(true);
          setGestureCategories(response.data.data);
          setTargetId(response.data.data[0]._id);
          return Promise.resolve(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedType]);

  // function onDragEnd(result) {
  //   if (!result.destination) return;
  //   const items = reorder(pages, result.source.index, result.destination.index);
  //   setPages(items);
  // }

  const handleEditorChange = (index) => (content, editor) => {
    const pageData = pages.splice(0);
    pageData[index].body = content;
    setPages(pageData);
  };

  const handleFileChange = (index) => ({ file, url }) => {
    const modifiedIndex = modifiedCover.splice(0);
    const pageData = pages.splice(0);

    if (!modifiedIndex.includes(index)) {
      setModifiedCover([...modifiedIndex, index]);
    }

    pageData[index].coverImage = file;
    pageData[index].coverImageURL = url;
    setPages(pageData);
  };

  const handleFileChangeCard = () => ({ file, url }) => {
    let updateImage;
    if (id) {
      setModifiedCard(file)

      updateImage = {...modifiedCard}
    } else {
      setCardImage(file);

      updateImage = {...card}
    }

    updateImage.cardImageURL = url;

    setCard(updateImage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedType) {
      
    }
    setSubmitting(true);    
    const articleFormData = new FormData();
    for (const page of pages) {
      if (page.coverImage) {
        articleFormData.append("coverImages[]", page.coverImage);
      }
      articleFormData.append(
        "coverImageURLs[]",
        page.coverImage ? "" : page.coverImageURL
      );

      articleFormData.append("title[]", page.title);
      articleFormData.append("body[]", page.body);
    }
    for (const cover of modifiedCover) {
      articleFormData.append("modifiedCover[]", cover);
    }

    if (cardImage) {
      articleFormData.append("cardImage", cardImage);
    }

    console.log(TypeArticles[selectedType] === TypeArticles.Link ? targetUrl : targetId);

    articleFormData.append("card", JSON.stringify({...card,
      cardImageURL: cardImage ? "" : card.cardImageURL}));
    articleFormData.append("modifiedCard", modifiedCard);
    articleFormData.append("articleCategory", selectedCategory);
    articleFormData.append("type", TypeArticles[selectedType]);
    articleFormData.append("target", TypeArticles[selectedType] === TypeArticles.Link ? targetUrl : targetId);
    articleFormData.append("textButton", textButton);
    articleFormData.append("isActive", isActive);
    articleFormData.append("isPublished", isPublished);

    refreshAccessToken((error, isSuccess) => {
      if (!isSuccess) {
        setSubmitting(false);
        if (error) console.error(error);
        return;
      }

      const request = id
        ? updateArticle.request(articleFormData, id, accessToken)
        : createArticle.request(articleFormData, accessToken);

      request
        .then((response) => {
          setSubmitting(false);
          history.replace("/article");
        })
        .catch((err) => {
          setSubmitting(false);
          if (err.response) {
            console.error(err.response.data);
            console.log(error.response.status);
          } else if (err.request) {
            console.error(err.request);
          }
        });
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3 className="mb-2">Article Editor</h3>
      <div className="article-card">
        <h4 className="mb-2">Card Article</h4>
          <ImageDropzone 
            preview={card.cardImageURL}
            onChange={handleFileChangeCard()}
          ></ImageDropzone>
          <div className="d-flex">
              <Input
              type="text"
              bsSize="lg"
              className="mt-2 mr-1 mb-1 font-weight-bold"
              placeholder="Title..."
              value={card.title}
              onChange={(e) => {
                const newCardContent = { 
                  ...card,
                  title: e.target.value, 
                };
                setCard(newCardContent);
              }}
            ></Input> 
            <Input
              type="textarea"
              bsSize="lg"
              className="mt-2 ml-1 mb-1 font-weight-bold"
              placeholder="Description..."
              value={card.body}
              onChange={(e) => {
                const newCardContent = { 
                  ...card,
                  body: e.target.value, 
                };
                setCard(newCardContent);
              }}
            ></Input>   
          </div>
        </div>
      <div className="article-editor-page">
      <h4 className="mb-2">Article Pages</h4>
      {pages.map((page, index) => (
        <div className="article-page" key={page._id}>
          <ImageDropzone
            preview={page.coverImageURL}
            onChange={handleFileChange(index)}
          ></ImageDropzone>
          <Input
            type="text"
            plaintext
            bsSize="lg"
            className="mt-2 mb-1 font-weight-bold"
            placeholder="Title..."
            value={page.title}
            onChange={(e) => {
              const newPagesContent = pages.splice(0);
              newPagesContent[index] = {
                ...page,
                title: e.target.value,
              };
              setPages(newPagesContent);
            }}
          ></Input>
          <Editor
            key={page._id + index}
            apiKey="iyk0wbwkghora7b1i9w4b9pjcdxu3kjawvwlq27w8edplmth"
            value={pages[index].body}
            init={{
              plugins: ["autolink link image code advlist lists anchor"],
              height: 500,
              toolbar:
                "undo redo | formatselect removeformat | bold italic underline | image link | lists advlist | code",
              file_picker_callback: function (callback, value, meta) {
                pickFile(false)
                  .then((file) => {
                    const imageForm = new FormData();
                    imageForm.append("files", file);
                    const uploadPath = "hearme/static/";
                    const uploadURL = new URL(
                      "upload?dir=" + encodeURIComponent(uploadPath),
                      baseURL
                    );
                    return axios.post(uploadURL, imageForm);
                  })
                  .then((response) => {
                    const [imgUrl] = response.data;
                    callback(imgUrl);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              },
            }}
            onEditorChange={handleEditorChange(index)}
          />
          <div className="d-flex justify-content-end mt-3">
            {pages.length > 1 && (
              <Button
              color="danger"
              onClick={() => {
                const newPages = pages.splice(0);
                newPages.splice(index, 1);
                setPages(newPages);
              }}
              className="mr-2"
              outline
              >
                Remove Page
              </Button>
            )}
            <ButtonGroup>
              <Button
                type="button"
                color="secondary"
                disabled={index === 0}
                onClick={() => {
                  const newOrder = pages.splice(0);
                  newOrder[index - 1] = pages[index];
                  newOrder[index] = pages[index - 1];
                  setPages(newOrder);
                }}
                outline
              >
                <span className="material-icons align-middle">remove</span>
              </Button>
              <Button disabled outline type="button">
                <i>Page {index + 1}</i>
              </Button>
              <Button
                type="button"
                color="secondary"
                outline
                onClick={() => {
                  const newOrder = pages.splice(0);
                  newOrder[index + 1] = pages[index];
                  newOrder[index] = pages[index + 1];
                  setPages(newOrder);
                }}
                disabled={index === pages.length - 1}
              >
                <span className="material-icons align-middle">add</span>
              </Button>
            </ButtonGroup>
          </div>
        </div>
      ))}
      </div>
      <Button
        type="button"
        block
        color="secondary"
        outline
        onClick={() => {
          const newPages = pages.splice(0);
          newPages.push({
            _id: generateTempId(),
            title: "",
            body: "",
            coverImage: null,
            coverImageURL: "",
          });
          setPages(newPages);
        }}
      >
        Add Page <span className="material-icons align-middle">add</span>
      </Button>
      <hr />
      {/* <FormGroup>
        <Label>Tags</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            {tags.map((tag, i) => (
              <Fragment key={tag + i}>
                <InputGroupText key={tag + i + "tag"}>{tag}</InputGroupText>
                <Button
                  type="button"
                  onClick={() => {
                    const tagList = Array.from(tags);
                    tagList.splice(i, 1);
                    setTags(tagList);
                  }}
                  key={tag + i}
                  className="p-0 mr-2"
                >
                  <span className="material-icons align-middle">clear</span>
                </Button>
              </Fragment>
            ))}
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="example: news, campaign"
            onKeyUp={(e) => {
              if ([" ", ",", ";"].includes(e.key)) {
                if (!e.target.value.trim()) return;
                const tagList = Array.from(tags);
                const newTag = e.target.value + "";
                tagList.push(newTag.substring(0, newTag.length - 1).trim());
                e.target.value = "";
                setTags(tagList);
              } else if (e.key === "Backspace") {
                if (e.target.value === "") {
                  const tagList = Array.from(tags);
                  tagList.pop();
                  setTags(tagList);
                }
              }
            }}
          ></Input>
        </InputGroup>
      </FormGroup> */}
      <FormGroup>
        <Label>Category</Label>
        <Input
          type="select"
          value={selectedCategory}
          onChange={async (e) => {
            setSelectedCategory(e.target.value);
          }}
        >
          <option key="foo" value="" disabled>
            --Select one category--
          </option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Type</Label>
        <Input
          type="select"
          value={selectedType}
          onChange={async (e) => {
            setSelectedType(e.target.value);
          }}
        >
          <option key="foo" value="" disabled>
            --Select one Type--
          </option>
          {Object.keys(TypeArticles).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Input>
      </FormGroup>
      {
        TypeArticles[selectedType] === TypeArticles.Link 
        ? <FormGroup>
            <Label>Input Link</Label>
            <Input
              type="text"
              plaintext
              className="border p-2"
              placeholder="Targek link"
              value={targetUrl}
              onChange={async (e) => {
                setTargetUrl(e.target.value);
              }}
            >
            </Input>
          </FormGroup>
        : TypeArticles[selectedType] === TypeArticles.NavigationPlayMe || TypeArticles[selectedType] === TypeArticles.NavigationLearnMe 
          ? <FormGroup>
              <Label>Selected Category Gesture</Label>
              <Input
                type="select"
                value={targetId}
                onChange={async (e) => {
                  setTargetId(e.target.value);
                }}
              >
                { gestureCategories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))
                }
              </Input>
            </FormGroup>
          : <></>
      }
      {
        selectedType && TypeArticles[selectedType] !== TypeArticles.Normal
        ? <FormGroup>
            <Label>Input Text Button</Label>
            <Input
              type="text"
              plaintext
              className="border p-2"
              placeholder="Text Button"
              value={textButton}
              onChange={async (e) => {
                setTextButton(e.target.value);
              }}
            >
            </Input>
          </FormGroup>
        :  <></>
      }
      <hr />
      <Row>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={isPublished}
                onChange={(e) => {
                  setPublished(!isPublished);
                }}
              />{" "}
              Published
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={isActive}
                onChange={(e) => {
                  setActive(!isActive);
                }}
              />{" "}
              Active
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <Button
        type="submit"
        disabled={isSubmitting}
        className="my-2 align-middle"
        color="primary"
        block
      >
        Submit Article
      </Button>
    </form>
  );
}

export default ArticleEditor;
