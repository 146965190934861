import axios from "axios";
import { authURL } from "../config";

const login = (data, accessToken) => {
  const resourceUrl = new URL("login", authURL);

  return axios.post(resourceUrl, data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const getUsers = (params = "", accessToken) => {
  const resourceUrl = new URL("users?" + params, authURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getRoles = (params = "", accessToken) => {
  const resourceUrl = new URL("roles?" + params, authURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getOneUser = (id, accessToken) => {
  const resourceUrl = new URL("User/" + id, authURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const updateUser = (formData, id, accessToken) => {
  const resourceUrl = new URL("User/" + id, authURL);

  return axios.put(resourceUrl, formData, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const deleteUser = (id, accessToken) => {
  const resourceUrl = new URL(`User/${id}`, authURL);

  return axios.delete(resourceUrl, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const activatedUser = (id, status, accessToken) => {
  const resourceUrl = new URL(`User/${id}/${status}`, authURL);

  return axios.patch(resourceUrl, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};
const exportUserData = (params = "", accessToken) => {
  const resourceUrl = new URL("users/export.csv?" + params, authURL);

  console.log(resourceUrl);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export default {
  login,
  getUsers,
  getRoles,
  deleteUser,
  updateUser,
  activatedUser,
  getOneUser,
  exportUserData,
};
