import React, { useState, useEffect } from "react";
import { Toaster } from 'react-hot-toast';
import { Modal, Container, ModalBody, ModalHeader } from "reactstrap";
import DatePicker from "react-datepicker";

import Datatable, {
  DatatableColumn,
} from "../../../components/Datatable";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import useApi from "../../../hooks/useApi";
import LeaderboardAPI from "../../../api/leaderboard";

function Leaderboard(props) {
  //API CALL
  const getLeaderboardSApi = useApi(LeaderboardAPI.getLeaderboards);

  const [users, setUsers] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [showModalAnalytics, setShowModalAnalytics] = useState(false)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const toggleNotificationModal = () => setShowModalAnalytics(false);

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
  }

  useEffect(refreshTable, [page, pageSize, filters, orders]);
  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }
    });
  }, []);

  function refreshTable() {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }

      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${encodeURIComponent(filters[key])}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
      ];

      const { data: resps } = await getLeaderboardSApi.request(params.join("&"), accessToken)

      const userList = resps.data.map((userData) => ({
        ...userData,
        "user.profile.picture": (
          <img
            src={userData.user?.profile.picture || ""}
            alt={`${userData.user?.profile.name || ""}'s`}
            style={{
              width: "64px",
              height: "64px",
              objectFit: "cover",
              borderRadius: "100%",
            }} />
        ),
        "user.profile.name": <b>{userData.user?.profile.name || ""}</b>,
        isHearing: typeof userData.user?.isHearing !== "boolean"
          ? "Unknown"
          : userData.user?.isHearing
            ? "Hearing"
            : "Deaf",
        "user.email": userData.user?.email || "",
        createdAt: new Date(userData.user?.createdAt || "").toLocaleDateString(),
        updatedAt: new Date(userData.user?.updatedAt || "").toLocaleDateString(),
      }));
      setTotalPage(resps.pagination.totalPage);
      setUsers(userList);
    });
  }

  return (
    <>
      <h3>Leaderboard Quiz</h3>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Modal isOpen={showModalAnalytics} Button={toggleNotificationModal} modalClassName="d-flex justify-content-center">
        <Container className="">
        <ModalHeader toggle={toggleNotificationModal}>Data Analytics User</ModalHeader>          
          <ModalBody>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </ModalBody>
        </Container>
      </Modal>
      <Datatable
        dataset={users}
        totalPage={totalPage}
        hover
        onChange={handleTableChange}
      >
        <DatatableColumn style={{ width: "64px" }} field="user.profile.picture">
          Profile Picture
        </DatatableColumn>
        <DatatableColumn field="user.profile.name">
          Name
        </DatatableColumn>
        <DatatableColumn field="totalPoint" sortable>Point</DatatableColumn>
        <DatatableColumn field="totalScore" sortable>Score</DatatableColumn>
        <DatatableColumn field="user.email">
          E-Mail Address
        </DatatableColumn>
        <DatatableColumn field="isHearing">Hearing/Deaf</DatatableColumn>
      </Datatable>
    </>
  );
}

export default Leaderboard;
