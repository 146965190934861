import React, { useState, Fragment } from "react";
import axios from "axios";
import { 
  Button, 
  FormGroup, 
  Label, 
  Input
} from "reactstrap";

import { refreshAccessToken, accessToken } from "../auth/AuthCheck";
import { baseURL, NotificationTypes } from "../config";


export default function NotificationForm(props) {
    const { gesture, onSubmit, category, article } = props;
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationBody, setNotificationBody] = useState("");
    const [immediately, setImmediately] = useState(false);
    const [broadcastTimestamp, setBroadcastTimestamp] = useState(new Date());

    return (
      <form
        onSubmit={async (e) =>  {
          e.preventDefault();
          // Validate all data for not auto compile
          const payload = JSON.stringify(gesture ? gesture : category ? category : article);
          // this order is based on the NOTIFICATIONTYPE in config.js
          const indexOfType = gesture ? 0 : article ? 2 : 1;

          const navigatePage = gesture ? "/gestureDetailByNotification" : category ? "/gestureCategoryDetail" : "/articlesDetail";

          const data = {
            navigatePage,
            [gesture ? "gesture" : category ? "category" : "article"]: payload,
          };

          const offsetInMinutes = broadcastTimestamp.getTimezoneOffset();
          const offsetInMs = offsetInMinutes * 60 * 1000;
          const corectedBroadcastTimestamp = new Date(
            broadcastTimestamp.getTime() + offsetInMs
          );

          const notificationRequestData = {
            notification: {
              title: notificationTitle,
              body: notificationBody,
              imageUrl: gesture ? gesture.previewURL : article ? article.coverImageURL : null
            },
            data,
            immediate: immediately,
            broadcastTimestamp: corectedBroadcastTimestamp.getTime(),
            type: NotificationTypes[indexOfType][0],
          };

          refreshAccessToken( async (err, isSuccess) => {
            if (!isSuccess) {
              if (err) console.error(err);
              alert("Failed to submit notification request");
              return;
            }

            try {
              const notificationURL = new URL(
                "/notification/sendNotification",
                baseURL
              );

              await axios.post(notificationURL, notificationRequestData, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })

              setBroadcastTimestamp(new Date());
              setImmediately(false);
              setNotificationBody("");
              setNotificationTitle("");
              onSubmit();
            } catch (err) {
              console.error(err);
              alert("Failed to submit notification request");
            }
          });

          console.log("Submitted", notificationRequestData);  
        }}
      >
        <FormGroup>
          <Label>Selected Gesture</Label>
          <Input
            type="text"
            readOnly
            value={
              gesture 
                ? `Category: ${gesture.category.name}, Labels: ${gesture.labels[0].name}, ${gesture.labels[1]?.name === undefined ? "" : gesture.labels[1].name}` 
                : category  ? `Category: ${category.name}, Desc: ${category.description ?? 'null'}` : `Article: ${article.card.title}`
            }
          ></Input>
        </FormGroup>
        <FormGroup>
          <Label>Noticfication Title</Label>
          <Input
            type="text"
            name="title"
            required
            value={notificationTitle}
            onChange={(e) => {
              setNotificationTitle(e.target.value);
            }}
          ></Input>
        </FormGroup>
        <FormGroup>
          <Label>Notification text</Label>
          <Input
            type="textarea"
            name="body"
            required
            value={notificationBody}
            onChange={(e) => {
              setNotificationBody(e.target.value);
            }}
          ></Input>
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            name="immediate"
            onChange={() => setImmediately(!immediately)}
            checked={immediately}
          />{" "}
          Send Notification instantly
        </FormGroup>
        {!immediately && (
          <Fragment>
            <hr />
            <FormGroup>
              <Label>Schedule Notification</Label>
              <Input
                type="datetime-local"
                value={broadcastTimestamp.toISOString().split(".")[0]}
                onChange={(e) => {
                  // The date ISO string has to be stored in UTC+0 (Zulu time)
                  // even though the client machine is not in UTC/Zulu time
                  // for the sake of UI consistency
                  try {
                    const newDate = new Date(e.target.value + "Z");
                    if (newDate.getTime() === newDate.getTime())
                      setBroadcastTimestamp(newDate);
                  } catch (error) {
                    console.error("Invalid date");
                  }
                }}
              ></Input>
            </FormGroup>
          </Fragment>
        )}
        <Button type="submit" color="primary">
          Send
        </Button>
      </form>
    );
  }
  