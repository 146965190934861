import React, { useState } from "react";
import Dropzone from "react-dropzone";
import "./ImageDropzone.css";

function ImageDropzone(props) {
  const [readerError, setReaderError] = useState(null);

  const { onChange } = props;
  function handleDropZone(files) {
    const file = files[0];
    const reader = new FileReader();
    reader.onError = () => setReaderError(reader.error);
    reader.onloadend = () => {
      const url = reader.result;
      onChange({ file, url });
    };
    reader.readAsDataURL(file);
  }
  return (
    <Dropzone onDrop={handleDropZone}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className="dropzone" {...getRootProps()}>
            <input
              type="file"
              {...getInputProps()}
              accept=".png, .jpg, .jpeg"
            />
            {props.preview && (
              <img className="dropzone__preview" src={props.preview} />
            )}
            <p className="dropzone__label">
              {props.label || "Drop image file or click here"}
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}

export default ImageDropzone;
