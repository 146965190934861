import React from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  useHistory,
  BrowserRouter,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import { signOut } from "../../auth/AuthCheck";
import GestureList from "./gestures/GestureList";
import GestureCategoryList from "./gestureCategories/GestureCategoryList";
import GestureColaburationList from "./gestureColaburation/GestureColaburationList";
import GestureSortPage from "./gestureCategories/GestureSortPage";
import GestureUploader from "./gestures/GestureEditor";
import ArticleList from "./articles/ArticleList";
import ArticleEditor from "./articles/ArticleEditor";
import ArticleCategoryList from "./articleCategories/ArticleCategoryList";
import HelpCategoryList from "./helpCategories/HelpCategoryList";
import SlideshowList from "./slideshows/SlideshowList";
import HelpList from "./helps/HelpList";
import Feedback from "./feedbacks";
import AppConfig from "./appConfiguration";
import GestureAggregation from "./aggregation/GestureAggregation";
import UserList from "./user/UserList";
import QuizList from "./quiz/QuizList";
import QuizEditor from "./quiz/QuizEditor";
import Leaderboard from "./leaderboard/Leaderboard";
import CustomerList from "./customer/Customer";
import GestureJktUploader from "./gesturesJkt/GestureJktEditor";
import GestureJktList from "./gesturesJkt/GestureJktList";

function Mainframe(props) {
  const history = useHistory();
  const logout = () => {
    signOut((error, isSuccess) => {
      if (error) {
        console.error(error);
      }
      if (isSuccess) {
        console.log("Logout successful, redirecting...");
      } else {
        console.log("Logout is not successful, somehow");
      }

      history.replace("/signin");
    });
  };
  return (
    <React.Fragment>
      <Navbar color="light" light>
        <NavbarBrand>HearMe</NavbarBrand>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <a href="#!" onClick={logout}>
              Sign Out
            </a>
          </NavItem>
        </Nav>
      </Navbar>
      <BrowserRouter>
        <Container fluid>
          <Row>
            <Col sm="4" md="4" lg="3" xl="2" className="sidenav">
              <Nav vertical className="sidenav__nav">
                {/* <NavItem>
                  <Link to="/user">Users</Link>
                </NavItem> */}
                <NavItem>
                  <Link to="/gesture">Gestures</Link>
                </NavItem>
                <NavItem>
                  <Link to="/gesture-jkt">Gestures Jkt</Link>
                </NavItem>
                <NavItem>
                  <Link to="/user">User Data</Link>
                </NavItem>
                <NavItem>
                  <Link to="/customer">Customer Data</Link>
                </NavItem>
                <NavItem>
                  <Link to="/gestureCategory">Gesture Categories</Link>
                </NavItem>
                <NavItem>
                  <Link to="/gestureColaburation">Gesture Colaburation</Link>
                </NavItem>
                <NavItem>
                  <Link to="/articleCategory">Article Categories</Link>
                </NavItem>
                <NavItem>
                  <Link to="/article">Articles</Link>
                </NavItem>
                <NavItem>
                  <Link to="/leaderboard">Leaderboard</Link>
                </NavItem>
                <NavItem>
                  <Link to="/game">Game</Link>
                </NavItem>
                <NavItem>
                  <Link to="/helpCategory">Help Categories</Link>
                </NavItem>
                <NavItem>
                  <Link to="/help">Helps</Link>
                </NavItem>
                <NavItem>
                  <Link to="/slideshow">Article Slideshow</Link>
                </NavItem>
                <NavItem>
                  <Link to="/feedback">User Feedback</Link>
                </NavItem>
                <NavItem>
                  <Link to="/appConfig">Application Configuration</Link>
                </NavItem>
              </Nav>
            </Col>
            <Col className="main bg-light">
              <Switch>
                <Route path="/gesture/add">
                  <GestureUploader />
                </Route>
                <Route path="/gesture">
                  <GestureList />
                </Route>{" "}
                <Route path="/gesture-jkt/add">
                  <GestureJktUploader />
                </Route>
                <Route path="/gesture-jkt">
                  <GestureJktList />
                </Route>
                <Route path="/gestureCategory/sort/:categoryId">
                  <GestureSortPage />
                </Route>
                <Route path="/gestureCategory">
                  <GestureCategoryList />
                </Route>
                <Route path="/gestureColaburation">
                  <GestureColaburationList />
                </Route>
                <Route path="/articleCategory">
                  <ArticleCategoryList />
                </Route>
                <Route path="/game/:type/:id">
                  <QuizEditor />
                </Route>
                <Route path="/game/:type">
                  <QuizEditor />
                </Route>
                <Route path="/game">
                  <QuizList />
                </Route>
                <Route path="/article/edit/:id?">
                  <ArticleEditor />
                </Route>
                <Route path="/article">
                  <ArticleList />
                </Route>
                <Route path="/helpCategory">
                  <HelpCategoryList />
                </Route>
                <Route path="/help">
                  <HelpList />
                </Route>
                <Route path="/slideshow">
                  <SlideshowList />
                </Route>
                <Route path="/feedback">
                  <Feedback />
                </Route>
                <Route path="/appConfig">
                  <AppConfig />
                </Route>
                <Route path="/user">
                  <UserList />
                </Route>
                <Route path="/customer">
                  <CustomerList />
                </Route>
                <Route path="/leaderboard">
                  <Leaderboard />
                </Route>
                <Route path="/">
                  <GestureAggregation />
                </Route>
              </Switch>
            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default Mainframe;
