import React, { useState, useEffect } from "react";
import { Input, Form, FormGroup, Label, Button } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import "./GestureSort.css";
import { useHistory } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import GestureAPI from "../../../api/gesture";

function GestureSort(props) {
  const getGestures = useApi(GestureAPI.getGesturesByCategory);
  const updateGesture = useApi(GestureAPI.updateGesture);
  
  const { categoryId } = props;
  const [gestures, setGestures] = useState([]);
  const history = useHistory();

  useEffect(() => {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        alert("Error occured. GS-001");
        if (err) {
          console.error(err);
        }
      }
      
      return getGestures.request(categoryId, "pageSize=999&filters[isActive]=true", accessToken)
        .then((response) => {
          const gestures = response.data.data;
          const gestureList = gestures.map((gesture, index) => ({
            ...gesture,
            label: gesture.labels.map((label) => label.name).join(", "),
            order: gesture.order || index,
          }));

          setGestures(gestureList);
        });
    })
  }, [categoryId]);

  function reorder(list, startIndex, emndIndex) {
    const result = list.slice(0);
    const [removed] = result.splice(startIndex, 1);
    result.splice(emndIndex, 0, removed);

    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) return;

    const items = reorder(
      gestures,
      result.source.index,
      result.destination.index
    );

    setGestures(items.map((item, index) => ({ ...item, order: index })));
  }

  function submit() {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        alert("Error Occured. ERR-GS002");
        if (err) {
          console.error(err);
        }
      }

      try {
        for (const gesture of gestures) {
          await updateGesture.request(
            { order: gesture.order },
            gesture._id.trim(), 
            accessToken
            );
        }
        history.goBack();
      } catch (error) {
        alert("Error Occured. ERR-GS003");
      }
    });
  }

  return (
    <div className="dragndrop-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="gestureList">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {gestures.map((gesture, index) => (
                <Draggable
                  key={gesture._id || gesture.label}
                  index={index}
                  draggableId={gesture._id || gesture.label}
                >
                  {(provided, snapshot) => (
                    <div
                      className="dragndrop-draggable"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Form
                        inline
                        onSubmit={(e) => {
                          e.preventDefault();
                          const gestureList = reorder(
                            gestures,
                            index,
                            (parseInt(e.target.order.value) || index + 1) - 1
                          );
                          e.target.scrollIntoView();
                          setGestures(
                            gestureList.map((gesture, index) => ({
                              ...gesture,
                              order: index,
                            }))
                          );
                        }}
                      >
                        <FormGroup className="mr-2">
                          <Label># &nbsp;</Label>
                          <Input
                            type="text"
                            style={{ width: "4em" }}
                            defaultValue={index + 1}
                            key={index}
                            name="order"
                            onBlur={(e) => {
                              const destinationIndex =
                                (parseInt(e.target.value) || index + 1) - 1;
                              const gestureList = reorder(
                                gestures,
                                index,
                                destinationIndex
                              );
                              setGestures(
                                gestureList.map((gesture, index) => ({
                                  ...gesture,
                                  order: index,
                                }))
                              );
                            }}
                            onClick={(e) => {
                              e.target.select();
                            }}
                          />
                        </FormGroup>
                        {gesture.label}
                      </Form>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        color="primary"
        size="lg"
        onClick={submit}
        style={{ position: "fixed", bottom: "2em", right: "2em" }}
      >
        Save
      </Button>
    </div>
  );
}

export default GestureSort;
