import React, { Fragment, useState, useEffect } from "react";
import { Input, FormGroup, Button, Label, Row, Col } from "reactstrap";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ImageDropzone from "../../../components/ImageDropzone";
import ArticleSelector from "../../../components/ArticleSelector";
import useApi from "../../../hooks/useApi";
import SlideshowAPI from '../../../api/slideshow';
import "./SlideshowList.css";


function generateTempId() {
  const number = Math.round(Math.random() * 999999);
  return number.toString(36);
}

function SlideshowList(props) {
  // API Call
  const getSlideshowsApi = useApi(SlideshowAPI.getSlideshows);
  const createSlideshowApi = useApi(SlideshowAPI.createSlideshow);
  const updateSlideshowApi = useApi(SlideshowAPI.updateSlideshow);
  const deleteSlideshowApi = useApi(SlideshowAPI.deleteSlideshow);

  const [slideshows, setSlideshows] = useState([]);
  const [deletedSlideshows, setDeletedSlideshows] = useState([]);

  function reorder(list, startIndex, endIndex) {
    const result = list.slice(0);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  function refresh() {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
      }

      getSlideshowsApi.request("filter[isActive]=all", accessToken)
        .then((response) => {
          setSlideshows(response.data.data || []);
        })
        .catch((err) => {
          console.error(err);
          alert("Error Occured. ERR-SL01");
        });
    });
  }

  
  useEffect(refresh, []);


  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    let slideshowList = reorder(
      slideshows,
      result.source.index,
      result.destination.index
    );
    slideshowList = slideshowList.map((slideshow, i) => {
      slideshow.order = i;
      return slideshow;
    });
    setSlideshows(slideshowList);
  }

  async function submit() {
    let i = 0;
    for (const slideshow of slideshows) {
      const data = new FormData();
      if (slideshow.image) {
        data.append("image", slideshow.image);
      } else {
        data.append("imageURL", slideshow.imageURL);
      }
      data.append("title", slideshow.title);
      data.append("isActive", slideshow.isActive);
      data.append("article", slideshow.article._id);
      data.append("order", i);
      i++;
    
      const request = slideshow._id
        ? updateSlideshowApi.request(data, slideshow._id, accessToken)
        : createSlideshowApi.request(data, accessToken);
      
      await request;
    }

    const deletedSlideshowList = deletedSlideshows.filter(
      (slide) => slide._id != null
    );
    
    for (const deletedSlideshow of deletedSlideshowList) {
      await deleteSlideshowApi.request(deletedSlideshow._id, accessToken);
    }

    refresh();
  }

  function addSlideshow() {
    const slideshowList = slideshows.slice(0);
    slideshowList.push({
      id: generateTempId(),
      image: null,
      imageURL: "",
      title: "",
      isActive: false,
      // Controls
      isSelectingArticle: false,
    });
    setSlideshows(slideshowList);
  }

  function deleteSlideshow(index) {
    return () => {
      const slideshowList = slideshows.slice(0);
      const [removed] = slideshowList.splice(index, 1);
      const deletedSlideshowList = deletedSlideshows.slice(0);
      deletedSlideshowList.push(removed);

      setDeletedSlideshows(deletedSlideshowList);
      setSlideshows(slideshowList);
    };
  }

  function restoreSlideshow(index) {
    return () => {
      const deletedSlideshowList = deletedSlideshows.slice(0);
      const [removed] = deletedSlideshowList.splice(index, 1);
      const slideshowList = slideshows.slice(0);
      slideshowList.push(removed);

      setDeletedSlideshows(deletedSlideshowList);
      setSlideshows(slideshowList);
    };
  }

  return (
    <Fragment>
      <h3>Slideshow Editor</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="SlideshowDropper">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="slideshow-wrapper"
            >
              {slideshows.map((slideshow, index) => (
                <Draggable
                  key={slideshow._id || slideshow.id}
                  draggableId={slideshow._id || slideshow.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className="slideshow"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ImageDropzone
                        preview={slideshow.imageURL}
                        onChange={({ file, url }) => {
                          const slideshowList = slideshows.slice(0);
                          slideshowList[index].image = file;
                          slideshowList[index].imageURL = url;
                          setSlideshows(slideshowList);
                        }}
                      />
                      <FormGroup>
                        <Label>Title</Label>
                        <Input
                          type="text"
                          value={slideshow.title}
                          onChange={(e) => {
                            const slideshowList = slideshows.slice(0);
                            slideshowList[index].title = e.target.value;
                            setSlideshows(slideshowList);
                          }}
                        ></Input>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={slideshow.isActive}
                            onChange={(e) => {
                              const slideshowList = slideshows.slice(0);
                              slideshowList[
                                index
                              ].isActive = !slideshow.isActive;
                              setSlideshows(slideshowList);
                            }}
                          ></Input>
                          Active
                        </Label>
                      </FormGroup>
                      <Button
                        type="button"
                        block
                        color="secondary"
                        className="my-3"
                        onClick={() => {
                          const slideshowList = slideshows.slice(0);
                          slideshowList[
                            index
                          ].isSelectingArticle = !slideshow.isSelectingArticle;
                          setSlideshows(slideshowList);
                        }}
                      >
                        {slideshow.article
                          ? `Article: ${slideshow.article.pages[0].title}`
                          : "Select Article"}
                      </Button>
                      {slideshow.isSelectingArticle && (
                        <ArticleSelector
                          selected={
                            slideshow.article ? slideshow.article._id : null
                          }
                          onSelected={(selectedArticle) => {
                            const slideshowList = slideshows.slice(0);
                            slideshowList[index].article = selectedArticle;
                            slideshowList[index].isSelectingArticle = false;
                            setSlideshows(slideshowList);
                          }}
                        />
                      )}
                      <Row className="align-items-center">
                        <Col>
                          <Button
                            type="button"
                            onClick={deleteSlideshow(index)}
                            outline
                            block
                            color="secondary"
                          >
                            Delete
                          </Button>
                        </Col>
                        <Col>
                          <p className="mt-3 mb-0 text-right text-muted">
                            Slideshow page #{index + 1}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <Button block color="secondary" onClick={addSlideshow}>
                Add slideshow
              </Button>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {deletedSlideshows.length > 0 && (
        <div className="my-3">
          <h3>Deleted Slideshow list</h3>
          <p>
            This is the deleted slideshow list. Once the slideshow list is saved
            these slideshow will be deleted permanently
          </p>
          <ul>
            {deletedSlideshows.map((slideshow, index) => (
              <li className="my-1" key={index}>
                <div>
                  <p className="lead m-0">
                    {slideshow.title}
                    <Button
                      color="primary"
                      outline
                      className="mx-2"
                      size="sm"
                      onClick={restoreSlideshow(index)}
                    >
                      Restore
                    </Button>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Button
        block
        color="primary"
        onClick={submit}
        disabled={slideshows.length === 0}
        className="my-2"
      >
        Save
      </Button>
    </Fragment>
  );
}

export default SlideshowList;
