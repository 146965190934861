import axios from "axios";
import { baseURL } from "../config";


const getSlideshows = (params = "", accessToken) => {
  const resourceUrl = new URL("slideshow?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOneSlideshow = (id, accessToken) => {
  const resourceUrl = new URL("slideshow/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createSlideshow = (formData, accessToken) => {
  const resourceUrl = new URL("slideshow", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const updateSlideshow = (formData, id, accessToken) => {
  const resourceUrl = new URL("slideshow/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteSlideshow = (id, accessToken) => {
  const resourceUrl = new URL(`slideshow/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const activatedSlideshow = (id, status, accessToken) => {
  const resourceUrl = new URL(`slideshow/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const restoreSlideshow = (id, accessToken) => {
  const resourceUrl = new URL(`slideshow/${id}/restore`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

export default {
  getSlideshows,
  createSlideshow,
  deleteSlideshow,
  updateSlideshow,
  activatedSlideshow,
  restoreSlideshow,
  getOneSlideshow
};