import axios from "axios";
import { baseURL } from "../config";

//============================= GESTURE =============================================
const getGestures = (params = "", accessToken) => {
  const resourceUrl = new URL("gesture?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getGesturesByCategory = (categoryId, params, accessToken) => {
  const resourceUrl = new URL(
    `/gestureCategory/${categoryId}/gestures?` + params,
    baseURL
  );

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getOneGesture = (id, accessToken) => {
  const resourceUrl = new URL("gesture/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const createGesture = (formData, accessToken) => {
  const resourceUrl = new URL("gesture", baseURL);

  return axios.post(resourceUrl, formData, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const updateGesture = (formData, id, accessToken) => {
  const resourceUrl = new URL("gesture/" + id, baseURL);

  return axios.put(resourceUrl, formData, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const deleteGesture = (id, accessToken) => {
  const resourceUrl = new URL(`gesture/${id}`, baseURL);

  return axios.delete(resourceUrl, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const activatedGesture = (id, status, accessToken) => {
  const resourceUrl = new URL(`gesture/${id}/${status}`, baseURL);

  return axios.patch(resourceUrl, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

//============================= GESTURE HISTORY =============================================
const getGestureHistory = (params = "", accessToken) => {
  const resourceUrl = new URL("gestureHistory?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getGestureAggregate = (params = "", accessToken) => {
  const resourceUrl = new URL("gestureHistory/aggregate?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export default {
  getGestures,
  getGesturesByCategory,
  createGesture,
  deleteGesture,
  updateGesture,
  activatedGesture,
  getOneGesture,
  getGestureHistory,
  getGestureAggregate,
};
