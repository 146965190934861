import React, { useState, useEffect } from "react";
import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import { 
  Button, 
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import { Link, Route, useHistory } from "react-router-dom";

import GestureCategoryEditor from "./GestureCategoryEditor";
import NotificationForm from "../../../components/NotificationForm";
import useApi from "../../../hooks/useApi";
import GestureCategoryAPI from '../../../api/gestureCategory';

function GestureCategoryList(props) {
  //API CALL
  const getGestureCategories = useApi(GestureCategoryAPI.getGestureCategories);
  const deleteGestureCategory = useApi(GestureCategoryAPI.deleteGestureCategory);
  const restoreGestureCategory = useApi(GestureCategoryAPI.restoreGestureCategory);
  const activatedGestureCategory = useApi(GestureCategoryAPI.activatedGestureCategory);

  const [gestureCategories, setGestureCategories] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  // Data for notificationModalShow
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [indexGesture, setIndexGesture] = useState(0);
  const toggleNotificationModal = () =>
    setNotificationModalShow(!notificationModalShow);

  const history = useHistory();

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);

  function refreshTable() {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Something goes wrong. Error GC-DT01");
        }
        return;
      }

      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        "all=true",
        `page=${page - 1}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];

      getGestureCategories.request(params.join("&"), accessToken)
        .then((response) => {
          const { data, pagination } = response.data || [];
          const gestureCategoryList = data.map(
            (category, index) => ({
              ...category,
              name: category.deleted
                ? `${category.name} (deleted)`
                : category.name,
              icon: category.iconURL ? (
                <img
                  src={category.iconURL}
                  alt=""
                  className="img-preview-thumbnail"
                />
              ) : (
                <p>
                  <i>No preview available</i>
                </p>
              ),
              isActive: category.isActive ? (
                <>
                  <span className="material-icons table-control--hover-hide">
                    done
                  </span>
                  <Button
                    color="secondary"
                    className="table-control--hover-show"
                    outline
                    onClick={toggleActive(category._id, category.isActive)}
                  >
                    Deactivate
                  </Button>
                </>
              ) : (
                <>
                  <span className="material-icons table-control--hover-hide">
                    clear
                  </span>
                  <Button
                    color="secondary"
                    className="table-control--hover-show"
                    outline
                    onClick={toggleActive(category._id, category.isActive)}
                  >
                    Activate
                  </Button>
                </>
              ),
              controls: (
                <>
                  <Link
                    to={`/gestureCategory/edit/${category._id}`}
                    className="btn btn-primary table-control--hover-show"
                  >
                    <span className="material-icons align-middle">edit</span>
                  </Link>
                  <Button
                    color="success"
                    className="table-control--hover-show"
                    onClick={() => {
                      refreshAccessToken((err, isSuccess) => {
                        if (!isSuccess) {
                          if (err) console.error(err.message);
                          alert("Failed to Submit Notification Request")
                          return;
                        }
                        setSelectedCategory(category);
                        setNotificationModalShow(true);
                        setIndexGesture(index);
                      })
                    }}
                  >
                    <span className="material-icons align-middle">campaign</span>
                  </Button>
                  {category.deleted ? (
                    <Button
                      color="secondary"
                      className="table-control--hover-show"
                      onClick={handleRestore(category._id)}
                    >
                      <span className="material-icons align-middle">
                        restore
                      </span>
                    </Button>
                  ) : (
                    <Button
                      color="danger"
                      className="table-control--hover-show"
                      onClick={handleDelete(category._id)}
                    >
                      <span className="material-icons align-middle">
                        delete
                      </span>
                    </Button>
                  )}
                  <Link
                    to={`/gestureCategory/sort/${category._id}`}
                    className="btn btn-primary table-control--hover-show"
                  >
                    Sort
                  </Link>
                </>
              ),
            })
          );
          setTotalPage(pagination.totalPage);
          setGestureCategories(gestureCategoryList);
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR GC-DT02");
        });
    });
  }

  const toggleActive = (id, isActive) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        alert("Error occured, ERR RTOKEN-00");
        return;
      }

      activatedGestureCategory.request(id, isActive ? "deactivate" : "activate", accessToken)
        .then((_) => refreshTable())
        .catch((error) => {
          console.error(error);
          alert("Something Went Wrong, Err GC-0D");
        });
    });
  };

  const handleDelete = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      
      if (!window.confirm("Are you sure?")) return;

      deleteGestureCategory.request(id, accessToken)
        .then((_) => refreshTable())
        .catch((error) => {
          console.error(error);
          alert("Something Went Wrong, Err GC-0D");
        });
    });
  };

  const handleRestore = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }

      restoreGestureCategory.request(id, accessToken)
        .then((_) => refreshTable())
        .catch((error) => {
          console.error(error);
          alert("Something Went Wrong, Err GC-0D");
        });
    });
  };


  return (
    <>  
    {/* Broadcast Modal */}
    <Modal isOpen={notificationModalShow} toggle={toggleNotificationModal}>
      <ModalHeader toggle={toggleNotificationModal}>Notify User</ModalHeader>
      <ModalBody>
        <p className="text-muted">Notify user about this gesture</p>
        {selectedCategory && (
          <NotificationForm
            onSubmit={() => setNotificationModalShow(false)}
            category={selectedCategory}
            index={indexGesture}
            page={page}
          ></NotificationForm>
        )}
      </ModalBody>
    </Modal>
      <h3>Gesture Category List</h3>
      <Route exact path="/gestureCategory/edit/:id?">
        <>
          <hr className="my-2" />
          <GestureCategoryEditor
            onSubmit={() => {
              refreshTable();
              history.replace("/gestureCategory");
            }}
          />
          <hr className="my-2" />
        </>
      </Route>
      <Route exact path="/gestureCategory">
        <Link to="/gestureCategory/edit" className="btn btn-primary">
          Add new
        </Link>
      </Route>
      <Datatable
        dataset={gestureCategories}
        totalPage={totalPage}
        onChange={handleTableChange}
        hover
      >
        <DatatableFilter type="select" field="isActive" label="Active Status">
          <option value="all">All</option>
          <option value={true}>Active</option>
          <option value={false}>Not Active</option>
        </DatatableFilter>
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        <DatatableColumn field="icon"></DatatableColumn>
        <DatatableColumn field="name" sortable>
          Category name
        </DatatableColumn>
        <DatatableColumn field="description">
          Description
        </DatatableColumn>
        <DatatableColumn field="isActive" sortable>
          Active
        </DatatableColumn>
        <DatatableColumn field="controls">&nbsp;</DatatableColumn>
      </Datatable>
    </>
  );
}

export default GestureCategoryList;
