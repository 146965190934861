import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Link, Route, useHistory } from "react-router-dom";

import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import { baseURL } from "../../../config";
import HelpCategoryEditor from "./HelpCategoryEditor";
import useApi from "../../../hooks/useApi";
import HelpAPI from "../../../api/help";


function HelpCategoryList(props) {
  //API CALL
  const getHelpCategories = useApi(HelpAPI.getHelpCategories);
  const activatedHelpCategory = useApi(HelpAPI.activatedHelpCategory);
  const deleteHelpCateory = useApi(HelpAPI.deleteHelpCateory);
  const restoreHelpCateory = useApi(HelpAPI.restoreHelpCateory);
  
  const [helpCategories, setHelpCategories] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const history = useHistory();

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  const toggleActive = (id, isActive) => () => {
    const resourceUrl = new URL(
      `helpCategory/${id}/${isActive ? "deactivate" : "activate"}`,
      baseURL
    );
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        alert("Error occured, ERR RTOKEN-00");
        return;
      }
      return axios
        .patch(resourceUrl, null, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          refreshTable();
        })
        .catch((error) => {
          console.error(error);
          alert("Something wrong happened, Error AC-0A");
        });
    });
  };

  const handleDelete = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      if (!window.confirm("Are you sure?")) return;
      axios
        .delete(new URL(`helpCategory/${id}`, baseURL), {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error AC-0D");
        });
    });
  };

  const handleRestore = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      
      axios
        .patch(new URL(`helpCategory/${id}/restore`, baseURL), null, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((_) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error AC-0R");
        });
    });
  };

  function refreshTable() {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Something goes wrong. Error GC-DT01");
        }
        return;
      }

      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page - 1}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];
      const resourceURL = new URL("helpCategory?" + params.join("&"), baseURL);
      return axios
        .get(resourceURL, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          const helpCategoryData = response.data || [];
          const helpCategoryList = helpCategoryData.data.map((category) => ({
            ...category,
            name: category.deleted
              ? `${category.name} (deleted)`
              : category.name,
            isActive: category.isActive ? (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  done
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(category._id, category.isActive)}
                >
                  Deactivate
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  clear
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(category._id, category.isActive)}
                >
                  Activate
                </Button>
              </Fragment>
            ),
            controls: (
              <Fragment>
                <Link
                  to={`/helpCategory/edit/${category._id}`}
                  className="btn btn-primary table-control--hover-show"
                >
                  <span className="material-icons align-middle">edit</span>
                </Link>
                {category.deleted ? (
                  <Button
                    color="secondary"
                    className="table-control--hover-show"
                    onClick={handleRestore(category._id)}
                  >
                    <span className="material-icons align-middle">restore</span>
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    className="table-control--hover-show"
                    onClick={handleDelete(category._id)}
                  >
                    <span className="material-icons align-middle">delete</span>
                  </Button>
                )}
              </Fragment>
            ),
          }));
          setTotalPage(helpCategoryData.pagination.totalPage);
          setHelpCategories(helpCategoryList);
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR AC-DT02");
        });
    });
  }
  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);

  return (
    <Fragment>
      <h3>Help Category List</h3>
      <Route exact path="/helpCategory/edit/:id?">
        <Fragment>
          <hr className="my-2" />
          <HelpCategoryEditor
            onSubmit={() => {
              refreshTable();
              history.replace("/helpCategory");
            }}
          />
          <hr className="my-2" />
        </Fragment>
      </Route>
      <Route exact path="/helpCategory">
        <Link to="/helpCategory/edit" className="btn btn-primary">
          Add new
        </Link>
      </Route>
      <Datatable
        dataset={helpCategories}
        totalPage={totalPage}
        onChange={handleTableChange}
        hover
      >
        <DatatableFilter type="select" field="isActive" label="Active Status">
          <option value="all">All</option>
          <option value={true}>Active</option>
          <option value={false}>Not Active</option>
        </DatatableFilter>
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        <DatatableColumn field="name" sortable>
          Category name
        </DatatableColumn>
        <DatatableColumn field="description" sortable>
          Description
        </DatatableColumn>
        <DatatableColumn field="isActive" sortable>
          Active
        </DatatableColumn>
        <DatatableColumn field="controls">&nbsp;</DatatableColumn>
      </Datatable>
    </Fragment>
  );
}

export default HelpCategoryList;
