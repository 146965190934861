import React, { useState, useEffect } from "react";
import { Input, FormGroup, Button, Label, Row, Col } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";

import QuestionSort from './QuestionSort';
import QuizAPI from "../../../api/quiz";
import useApi from "../../../hooks/useApi";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import GestureCategoryAPI from "../../../api/gestureCategory";

import "../../../components/ImageDropzone.css"
import "./QuizEditor.css";



function QuizEditor(props) {
  //API CALL
  const createQuiz = useApi(QuizAPI.createQuiz);
  const updateQuiz = useApi(QuizAPI.updateQuiz);
  const getGestureCategories = useApi(GestureCategoryAPI.getGestureCategories);
  const getOneQuiz = useApi(QuizAPI.getOneQuiz);

  const [categoryIconURL, setCategoryIconURL] = useState("");
  const [gestureCategories, setGestureCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [successCreated, setSuccessCreated] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [quizId, setquizId] = useState("")

  const { id } = useParams();
  const history = useHistory();

  const ButtonCancel = () => (
    <Button
      outline
      type="reset"
      className="mt-2"
      block
      color="secondary"
      >
      Cancel
    </Button>
  );

  const ButtonAddQuestion = ({ type }) => {
    switch (type) {
      case "reset":
        return (
          <Button
            type="reset"
            block
            color="secondary"
            outline
            >
            Back
          </Button>);
      default:
        return (
          <Button
            type="button"
            block
            color="primary"
            outline
            onClick={() => {
              let sumOption = questions.length
              console.log(questions);
              setQuestions([...questions, sumOption++])
            }}
            >
            Add Question <span className="material-icons align-middle">add</span>
          </Button>
        );
    }
  };

  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Error occured. ERR-GCE01");
        }
        return;
      }

      getGestureCategories.request("pageSize=999&all=true", accessToken)
        .then((response) => {
          const data = response.data.data;

          if (!id) {
            setCategoryIconURL(data[0].iconURL);
            setSelectedCategory(data[0]._id);
          }

          setGestureCategories(data);
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR GC-02");
        })

      id && getOneQuiz.request(id, accessToken)
        .then(({ data }) => {
          const { categoryGestureId, questions, _id } = data;
          setQuestions(questions);
          setquizId(_id);
          setSelectedCategory(categoryGestureId._id);
          setCategoryIconURL(categoryGestureId.iconURL);
        })
        .catch((err) => {
          console.error(err);
          alert("Error Occured, ERR-GCE02");
        })
    })
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        alert("ERR-GCE03");
        return;
      }

      const request = id
        ? updateQuiz.request({}, id, accessToken)
        : createQuiz.request({}, selectedCategory, accessToken)

      request
        .then((response) => {
          setSuccessCreated(true);
          setquizId(response.data._id);
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured. ERR-GCE04");
        });
    });
  };

  return (
    <>
    <h3>Game Uploader</h3>
    <div className="gesture-category-wrapper">
      <form
        onReset={(e) => history.goBack("/game")}
        onSubmit={handleSubmit}
      >
        <section>
          <div className="dropzone" >
            {categoryIconURL && (
              <img className="dropzone__preview" alt={categoryIconURL} src={categoryIconURL} />
            )}
            <p className="dropzone__label">
              {props.label || "Selected Category"}
            </p>
          </div>
        </section>
        <FormGroup>
          <Label> Selected Gesture Category</Label>
          <Input
            type="select"
            value={selectedCategory}
            required
            onChange={(e) => {
              const categoryIndex = e.target.value;
              const index = gestureCategories.findIndex(object => {
                return object._id === categoryIndex;
              });
              setCategoryIconURL(gestureCategories[index].iconURL);
              setSelectedCategory(e.target.value);
            }}
            >
            { gestureCategories.map((category) => 
                (<option key={category._id} value={category._id}>
                    {category.name}
                  </option>)
              )
            }
          </Input>
        </FormGroup>      
        {
          successCreated && questions.map((_, index) => <QuestionSort categoryId={selectedCategory} quizId={quizId} key={index} draggableId={index} />)
        }
        {
          id && questions.map((quiz, idx) => <QuestionSort question={quiz.question} categoryId={selectedCategory} quizId={quizId} keyindexOf={idx} draggableId={idx} />)
        }
        {
          !id 
            ? !successCreated 
              ? <Row>
                  <Col>
                    <Button type="submit" className="mt-2" block color="primary">
                      Submit Quiz Bucket
                    </Button>
                  </Col>
                  <Col>
                    <ButtonCancel />
                  </Col>
                </Row>
              : <ButtonAddQuestion
                  type={ questions.length === 5 ? "reset" : "button"}
                />
            : questions.length === 5 
                ? <ButtonCancel />
                : <ButtonAddQuestion
                    type={ questions.length === 5 ? "reset" : "button"}
                  />
        }
      </form>
    </div>
    </>
  );
}

export default QuizEditor;
