import axios from "axios";
import { baseURL } from "../config";

// ==================================  HELP  ==============================
const getHelps = (params = "", accessToken) => {
  const resourceUrl = new URL("help?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOneHelp = (id, accessToken) => {
  const resourceUrl = new URL("help/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createHelp = (formData, accessToken) => {
  const resourceUrl = new URL("help", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const updateHelp = (formData, id, accessToken) => {
  const resourceUrl = new URL("help/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteHelp = (id, accessToken) => {
  const resourceUrl = new URL(`help/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const activatedHelp = (id, status, accessToken) => {
  const resourceUrl = new URL(`help/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const restoreHelp = (id, accessToken) => {
  const resourceUrl = new URL(`help/${id}/restore`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

// ==================================  HELP CATEGORY  ==============================
const getHelpCategories = (params, accessToken) => {
  const resourceUrl = new URL("helpCategory?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOneHelpCategory = (id, accessToken) => {
  const resourceUrl = new URL("helpCategory/" + id.trim(), baseURL);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createHelpCategory = (formData, accessToken) => {
  const resourceUrl = new URL("helpCategory", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const updateHelpCategory = (formData, id, accessToken) => {
  const resourceUrl = new URL("helpCategory/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteHelpCateory = (id, accessToken) => {
  const resourceUrl = new URL(`helpCategory/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const activatedHelpCategory = (id, status, accessToken) => {
  const resourceUrl = new URL(`helpCategory/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const restoreHelpCateory = (id, accessToken) => {
  const resourceUrl = new URL(`helpCategory/${id}/restore`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

export default {
  getHelps,
  createHelp,
  deleteHelp,
  updateHelp,
  activatedHelp,
  restoreHelp,
  getOneHelp,
  getHelpCategories,
  getOneHelpCategory,
  createHelpCategory,
  updateHelpCategory,
  deleteHelpCateory,
  activatedHelpCategory,
  restoreHelpCateory
};