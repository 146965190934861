import axios from "axios";
import { baseURL } from "../config";


const getCoverImages = (params = "", accessToken) => {
  const resourceUrl = new URL("/frontpage/coverImage?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getOnoCoverImage = (id, accessToken) => {
  const resourceUrl = new URL("slideshow/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createCoverImage = (formData, accessToken) => {
  const resourceUrl = new URL("/frontpage/coverImage", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

export default {
  getCoverImages,
  createCoverImage,
  getOnoCoverImage
};