import React, { useState, useEffect } from "react";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import ImageDropzone from "../../../components/ImageDropzone";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import ArticleSelector from "../../../components/ArticleSelector";
import useApi from "../../../hooks/useApi";
import AppConfAPI from "../../../api/appConfiguration";
import ArticleAPI from "../../../api/article";

import "./AppConfig.css";

function AppConfig(props) {
  // API Call
  const getCoverImage = useApi(AppConfAPI.getCoverImages);
  const createCoverImage = useApi(AppConfAPI.createCoverImage);
  const getActionArticle = useApi(ArticleAPI.getActionArticle);

  const [imgURL, setImgURL] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [actionType, setActionType] = useState("article");
  const [actionUrl, setActionUrl] = useState("");
  const [actionArticle, setActionArticle] = useState(null);
  const [isArticleModalOpen, setArticleModalOpen] = useState(false);
  const [selectedArticleTitle, setSelectedArticleTitle] = useState("");
  const [isFormValid, setFormValid] = useState(false);
  const toggleArticleSelectionModal = () =>
    setArticleModalOpen(!isArticleModalOpen);

  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        // alert("[ERR-SET00] Failed to retrieve data");
      } else {
        getCoverImage
          .request()
          .then((response) => {
            setImgURL(response.data.highlightImgUrl);
            setActionType(response.data.actionType || "article");
            setActionArticle(response.data.actionArticle || null);
            setActionUrl(response.data.actionURL || "");

            if (actionArticle) {
              return getActionArticle.request(actionArticle);
            }
          })
          .then((response) => {
            if (response) {
              setSelectedArticleTitle(response.data.pages[0].title);
            }
          })
          .catch((err) => {
            console.error(err);
            // alert("[ERR-SET01] Failed to retrieve data");
          });
      }
    });
  }, [actionArticle, getActionArticle, getCoverImage]);

  function submitNewImage(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("actionType", actionType);
    if (actionType === "article")
      formData.append("actionArticle", actionArticle);
    if (actionType === "url") formData.append("actionURL", actionUrl);
    if (!imgFile && imgURL) {
      formData.append("highlightImgUrl", imgURL);
    } else {
      formData.append("coverImage", imgFile);
    }

    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        alert("[ERR-SET02] Failed to set data");
      } else {
        createCoverImage
          .request(formData, accessToken)
          .then(({ data }) => {
            setImgURL(data.highlight.highlightImgUrl);
          })
          .catch((err) => {
            console.error(err);
            // alert("[ERR-SET02] Failed to set data");
          });
      }
    });
  }

  function handleFileChange({ file, url }) {
    setImgFile(file);
    setImgURL(url);
  }

  function checkFormValidity() {
    return (imgURL || imgFile) &&
      ((actionType === "article" && actionArticle) ||
        (actionType === "url" && actionUrl)) &&
      isFormValid
      ? true
      : false;
  }

  return (
    <>
      <Modal
        size="lg"
        isOpen={isArticleModalOpen}
        toggle={toggleArticleSelectionModal}
      >
        <ModalHeader toggle={toggleArticleSelectionModal}>
          Select Article
        </ModalHeader>
        <ModalBody>
          <ArticleSelector
            selected={actionArticle}
            onSelected={(selectedArticle) => {
              setActionArticle(selectedArticle._id);
              setSelectedArticleTitle(selectedArticle.pages[0].title);
              setArticleModalOpen(false);
            }}
          ></ArticleSelector>
        </ModalBody>
      </Modal>
      <h1>Application Settings</h1>
      <hr />
      <h4>Front Page Highlight Image</h4>
      <form
        onSubmit={submitNewImage}
        onChange={(e) => {
          setFormValid(e.target.checkValidity());
        }}
      >
        <Row>
          <Col>
            <ImageDropzone
              required={!imgURL}
              onChange={handleFileChange}
              preview={imgURL}
            ></ImageDropzone>
          </Col>
          <Col>
            <FormGroup>
              <Label>Type</Label>
              <Input
                type="select"
                name="type"
                id="type"
                value={actionType}
                onChange={(e) => {
                  setActionType(e.target.value);
                }}
              >
                <option value="article">Link to an Article</option>
                <option value="url">External Link (e.g. website page)</option>
              </Input>
            </FormGroup>
            {actionType === "url" && (
              <FormGroup>
                <Label>Link URL</Label>
                <Input
                  type="url"
                  placeholder="https://example.com/link/mylink"
                  value={actionUrl}
                  required
                  onChange={(e) => {
                    setActionUrl(e.target.value);
                  }}
                ></Input>
              </FormGroup>
            )}
            {actionType === "article" && (
              <>
                <FormGroup>
                  <Label>Selected Article</Label>
                  <Input
                    type="text"
                    readOnly
                    value={selectedArticleTitle || "No article selected"}
                  ></Input>
                </FormGroup>
                <Button
                  block
                  outline
                  color="secondary"
                  onClick={toggleArticleSelectionModal}
                >
                  Select Article
                </Button>
              </>
            )}
            <Button
              disabled={!checkFormValidity()}
              className="my-2"
              type="submit"
              color={checkFormValidity() ? "primary" : "secondary"}
              block
            >
              Save cover image settings
            </Button>
          </Col>
        </Row>
        <hr />
        <div className="mb-3">
          <h4>Preview</h4>
          <div className="preview-container">
            <div className="preview-wrapper">
              <div className="preview preview--iphonex">
                <div className="preview__front-camera"></div>
                <div className="preview__screen">
                  <div className="preview__topbar"></div>
                  <div className="preview__image-container"></div>
                  <img
                    src={imgURL}
                    alt="Hear Me Cover"
                    className="preview__image"
                  />
                  <div className="preview__content">
                    <img
                      className="preview__content__page-top"
                      src="/hearme-content-preview.png"
                      alt="hearme-preview"
                    />
                  </div>
                  <div className="preview__bottombar"></div>
                </div>
              </div>
              <p className="text-center">
                <strong>iPhone X Family</strong>
              </p>
            </div>
            <div className="preview-wrapper">
              <div className="preview preview--galaxys21">
                <div className="preview__front-camera"></div>
                <div className="preview__screen">
                  <div className="preview__topbar"></div>
                  <img
                    src={imgURL}
                    alt="Hear Me Cover"
                    className="preview__image"
                  />
                  <div className="preview__content">
                    <img
                      className="preview__content__page-top"
                      src="/hearme-content-preview.png"
                      alt="hearme-preview"
                    />
                  </div>
                </div>
              </div>
              <p className="text-center">
                <strong>Android Bezel-less (No Navbar)</strong>
              </p>
            </div>
            <div className="preview-wrapper">
              <div className="preview preview--galaxys21">
                <div className="preview__front-camera"></div>
                <div className="preview__screen">
                  <div className="preview__topbar"></div>
                  <img
                    src={imgURL}
                    alt="Hear Me Cover"
                    className="preview__image"
                  />
                  <div className="preview__content">
                    <img
                      className="preview__content__page-top"
                      src="/hearme-content-preview.png"
                      alt="hearme-preview"
                    />
                  </div>
                  <div className="preview__bottombar"></div>
                </div>
              </div>
              <p className="text-center">
                <strong>Android Bezel-less (With Navbar)</strong>
              </p>
            </div>
            <div className="preview-wrapper">
              <div className="preview preview--iphone-classic">
                {/* <div className="preview__front-camera"></div> */}
                <div className="preview__screen">
                  <div className="preview__topbar"></div>
                  <img
                    src={imgURL}
                    alt="Hear Me Cover"
                    className="preview__image"
                  />
                  <div className="preview__content">
                    <img
                      className="preview__content__page-top"
                      src="/hearme-content-preview.png"
                      alt="hearme-preview"
                    />
                  </div>
                </div>
              </div>
              <p className="text-center">
                <strong>iPhone Classic (16:9)</strong>
              </p>
            </div>
            <div className="preview-wrapper">
              <div className="preview preview--android-classic">
                {/* <div className="preview__front-camera"></div> */}
                <div className="preview__screen">
                  <div className="preview__topbar"></div>
                  <img
                    src={imgURL}
                    alt="Hear Me Cover"
                    className="preview__image"
                  />
                  <div className="preview__content">
                    <img
                      className="preview__content__page-top"
                      src="/hearme-content-preview.png"
                      alt="hearme-preview"
                    />
                  </div>
                  <div className="preview__bottombar"></div>
                </div>
              </div>
              <p className="text-center">
                <strong>Android Classic (16:9, Navbar)</strong>
              </p>
            </div>
            <div className="preview-wrapper">
              <div className="preview preview--android-classic">
                {/* <div className="preview__front-camera"></div> */}
                <div className="preview__screen">
                  <div className="preview__topbar"></div>
                  <img
                    src={imgURL}
                    alt="Hear Me Cover"
                    className="preview__image"
                  />
                  <div className="preview__content">
                    <img
                      className="preview__content__page-top"
                      src="/hearme-content-preview.png"
                      alt="hearme-preview"
                    />
                  </div>
                  {/* <div className="preview__bottombar"></div> */}
                </div>
              </div>
              <p className="text-center">
                <strong>Android Classic (16:9, No Navbar)</strong>
              </p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AppConfig;
