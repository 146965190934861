import axios from "axios";
import { authURL } from "../config";

const getCustomers = (params, accessToken) => {
  const resourceUrl = new URL("customer?" + params, authURL);

  return axios.get(resourceUrl, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getOneCustomer = (id, accessToken) => {
  const resourceUrl = new URL("customer/" + id, authURL);

  return axios.get(resourceUrl, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const updateCustomer = (formData, id, accessToken) => {
  const resourceUrl = new URL("customer/" + id.trim(), authURL);

  return axios.put(resourceUrl, formData, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const deleteCustomer = (id, accessToken) => {
  const resourceUrl = new URL(`customer/${id}`, authURL);

  return axios.delete(resourceUrl, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

const exportCustomers = (params = "", accessToken) => {
  const resourceUrl = new URL("customer/export.csv?" + params, authURL);

  console.log(resourceUrl);

  return axios.get(resourceUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export default {
  getCustomers,
  deleteCustomer,
  updateCustomer,
  getOneCustomer,
  exportCustomers,
};
