import axios from "axios";
import { baseURL } from "../config";

// =============================     ARTICLE      =======================================//
const getArticles = (params, accessToken) => {
  const resourceUrl = new URL("article?" + params, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const getActionArticle = (action = "", accessToken = "") => {
  const resourceUrl = new URL("article/" + action, baseURL);

  return axios.get(
    resourceUrl, 
    accessToken 
    ? {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    : null
  )
};

const getOneArticle = (id, accessToken) => {
  const resourceUrl = new URL("article/" + id, baseURL);

  return axios.get(resourceUrl, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  })
};

const createArticle = (formData, accessToken) => {
  const resourceUrl = new URL("article", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      "Content-Type": "multipart/form-data",
    }
  )
};

const updateArticle = (formData, id, accessToken) => {
  const resourceUrl = new URL("article/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      "Content-Type": "multipart/form-data",
    }
  )
};

const deleteArticle = (id, accessToken) => {
  const resourceUrl = new URL(`article/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const patchArticle = (id, status, accessToken) => {
  const resourceUrl = new URL(`article/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};



//==================================    ARTICLE CATEGORY    =========================================
const getArticleCategories = (params = "", accessToken = "") => {
  const resourceUrl = new URL("articleCategory?" + params, baseURL);

  return axios.get(resourceUrl, 
    accessToken ? 
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      } : null
  )
};

const getOneArticleCategory = (id = "", accessToken = "") => {
  const resourceUrl = new URL("articleCategory/" + id.trim(), baseURL);

  return axios.get(resourceUrl, 
    accessToken ? 
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      } : null
  )
};

const createArticleCategory = (formData = {}, accessToken = null) => {
  const resourceUrl = new URL("articleCategory", baseURL);
  
  return axios.post(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      "Content-Type": "multipart/form-data",
    }
  )
};

const updateArticleCategory = (formData, id, accessToken) => {
  const resourceUrl = new URL("articleCategory/" + id.trim(), baseURL);
  
  return axios.put(resourceUrl, 
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      "Content-Type": "multipart/form-data",
    }
  )
};

const patchArticleCategory = (id, status, accessToken) => {
  const resourceUrl = new URL(`articleCategory/${id}/${status}`, baseURL);
  
  return axios.patch(resourceUrl, null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};

const deleteArticleCategory = (id, accessToken) => {
  const resourceUrl = new URL(`articleCategory/${id}`, baseURL);
  
  return axios.delete(resourceUrl,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )
};


export default {
  getArticles,
  createArticle,
  deleteArticle,
  updateArticle,
  patchArticle,
  getOneArticle,
  getActionArticle,
  getArticleCategories,
  getOneArticleCategory,
  createArticleCategory,
  updateArticleCategory,
  patchArticleCategory,
  deleteArticleCategory
};