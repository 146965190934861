import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import { Link, Route } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import QuizAPI from "../../../api/quiz";

function QuizList(props) {
  //API CALL
  const getQuizs = useApi(QuizAPI.getQuizs);
  const activatedQuiz = useApi(QuizAPI.activatedQuiz);
  const deleteQuiz = useApi(QuizAPI.deleteQuiz);
  const restoreQuiz = useApi(QuizAPI.restoreQuiz);

  const [gestureCategories, setGestureCategories] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);
  function refreshTable() {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Something goes wrong. Error GC-DT01");
        }
        return;
      }

      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];

      try {
        const response = await getQuizs.request(params.join("&"), accessToken);

        const gestureCategoryData = response.data || [];
        const gestureCategoryList = gestureCategoryData.data.map(
          (category) => ({
            ...category.categoryGestureId,
            name: category.categoryGestureId.deleted
              ? `${category.categoryGestureId.name} (deleted)`
              : category.categoryGestureId.name,
            icon: category.categoryGestureId.iconURL ? (
              <img
                src={category.categoryGestureId.iconURL}
                alt=""
                className="img-preview-thumbnail" />
            ) : (
              <p>
                <i>No preview available</i>
              </p>
            ),
            isActive: category.isActive ? (
              <>
                <span className="material-icons table-control--hover-hide">
                  done
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(category._id, category.isActive)}
                >
                  Deactivate
                </Button>
              </>
            ) : (
              <>
                <span className="material-icons table-control--hover-hide">
                  clear
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(category._id, category.isActive)}
                >
                  Activate
                </Button>
              </>
            ),
            controls: (
              <>
                <Link
                  to={`/game/edit/${category._id}`}
                  className="btn btn-primary table-control--hover-show"
                >
                  <span className="material-icons align-middle">edit</span>
                </Link>
                {category.deleted ? (
                  <Button
                    color="secondary"
                    className="table-control--hover-show"
                    onClick={handleRestore(category._id)}
                  >
                    <span className="material-icons align-middle">
                      restore
                    </span>
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    className="table-control--hover-show"
                    onClick={handleDelete(category._id)}
                  >
                    <span className="material-icons align-middle">
                      delete
                    </span>
                  </Button>
                )}
              </>
            ),
          })
        );
        setTotalPage(gestureCategoryData.pagination.totalPage);
        setGestureCategories(gestureCategoryList);
      } catch (err_1) {
        console.error(err_1);
        alert("Error occured, ERR GC-DT02");
      }
    });
  }
  const toggleActive = (id, isActive) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        alert("Error occured, ERR RTOKEN-00");
        return;
      }
      return activatedQuiz
        .request(id, isActive ? "deactivate" : "activate", accessToken)
          .then((response) => {
            refreshTable();
          })
          .catch((error) => {
            console.error(error);
            alert("Something wrong happened, Error GC-0A");
          });
    });
  };

  const handleDelete = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      if (!window.confirm("Are you sure?")) return;

      deleteQuiz.request(id, accessToken)
        .then((_) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error GC-0D");
        });
    });
  };

  const handleRestore = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }

      restoreQuiz.request(id, accessToken)
        .then((_) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error AC-0R");
        });
    });
  };

  return (
    <>
      <h3>Game List</h3>
      <Route exact path="/game">
        <Link to="/game/add" className="btn btn-primary">
          Add new
        </Link>
      </Route>
      <Datatable
        dataset={gestureCategories}
        totalPage={totalPage}
        onChange={handleTableChange}
        hover
      >
        <DatatableFilter type="select" field="isActive" label="Active Status">
          <option value="all">All</option>
          <option value={true}>Active</option>
          <option value={false}>Not Active</option>
        </DatatableFilter>
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        <DatatableColumn field="icon"></DatatableColumn>
        <DatatableColumn field="name">
          Category name
        </DatatableColumn>
        <DatatableColumn field="isActive" sortable>
          Active
        </DatatableColumn>
        <DatatableColumn field="controls">Action</DatatableColumn>
      </Datatable>
    </>
  );
}

export default QuizList;
