import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  ModalBody,
  ModalHeader,
  ButtonGroup,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";

import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import { accessToken, refreshAccessToken } from "../../../auth/AuthCheck";
import useApi from "../../../hooks/useApi";
import CustomerAPI from "../../../api/customer";

function CustomerList(props) {
  //API CALL
  const getCustomers = useApi(CustomerAPI.getCustomers);
  const getToExportCustomers = useApi(CustomerAPI.exportCustomers);

  const notifyErr = (message) => toast.error(message);
  const notify = (message) => toast.success(message);
  const toastLoad = () => toast.loading("Loading...", { duration: 8000 });

  const [users, setUsers] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showModalAnalytics, setShowModalAnalytics] = useState(false);
  const [csvDelimiter, setCsvDelimiter] = useState(",");

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function saveStringToFile(content, fileName) {
    const tempA = document.createElement("a");
    tempA.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`
    );
    notify("Data berhasil diunduh");

    tempA.setAttribute("download", fileName);
    tempA.click();
  }

  function exportCustumers(e) {
    e.preventDefault();
    // eslint-disable-next-line no-unused-expressions
    refreshAccessToken((err, isSuccess) => {
      const toastId = toastLoad();
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }

      getToExportCustomers
        .request(`delimiter=${csvDelimiter}&${setParams(false)}`, accessToken)
        .then((response) => {
          if (!response.data) {
            return notifyErr("maaf data tidak ditemukan");
          }
          const content = response.data;
          const fileName = `HearMe_CustomerData_export_${new Date().getTime()}.csv`;

          saveStringToFile(content, fileName);
        })
        .catch((err) => {
          console.log("ERROR", err);
        })
        .finally(() => toast.dismiss(toastId));
    });
  }

  const toggleNotificationModal = () => setShowModalAnalytics(false);

  function setParams(usedAllParams = true) {
    const filterQueries = Object.keys(filters)
      .map((key) => `filter[${key}]=${encodeURIComponent(filters[key])}`)
      .join("&");
    const orderQueries = Object.keys(orders)
      .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
      .join("&");
    const params = [
      `page=${page - 1}`,
      `pageSize=${pageSize}`,
      filterQueries,
      orderQueries,
      `q=${encodeURIComponent(searchQuery) || ""}`,
    ];

    return usedAllParams ? params : `${filterQueries}&${orderQueries}`;
  }

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);
  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }
    });
  }, []);

  function refreshTable() {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }

      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${encodeURIComponent(filters[key])}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${encodeURIComponent(searchQuery) || ""}`,
      ];

      const { data: resps } = await getCustomers.request(
        params.join("&"),
        accessToken
      );
      const userList = resps.data.map((userData) => ({
        ...userData,
        action: (
          <>
            <ButtonGroup>
              <a
                href="#selectedGesture"
                className="btn btn-outline-primary d-flex align-items-center "
                // disabled={
                // selectedGesture && selectedGesture._id === data._id
                // }
                onClick={() => {
                  // selectGesture(data);
                }}
              >
                <span className="material-icons">email</span>
                <b> Contact</b>
              </a>
            </ButtonGroup>
          </>
        ),
      }));
      setTotalPage(resps.pagination.totalPage);
      setUsers(userList);
    });
  }

  return (
    <>
      {" "}
      <h3>Customer List</h3>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mx-3">User List</h3>
        <div className="export-control-wrapper">
          <fieldset className="border-1">
            <legend>Data export</legend>
            <Form inline onSubmit={exportCustumers}>
              <FormGroup>
                <Label>CSV Delimiter</Label>
                <Input
                  type="select"
                  className="mx-3"
                  value={csvDelimiter}
                  onChange={(e) => setCsvDelimiter(e.target.value)}
                >
                  <option value=",">Comma (,) [default]</option>
                  <option value=";">
                    Semicolon (;) [better for Microsoft Excel]
                  </option>
                </Input>
              </FormGroup>
              <Button type="submit">Export</Button>
            </Form>
          </fieldset>
        </div>
      </div>
      <Modal
        isOpen={showModalAnalytics}
        Button={toggleNotificationModal}
        modalClassName="d-flex justify-content-center"
      >
        <Container className="">
          <ModalHeader toggle={toggleNotificationModal}>
            Data Analytics User
          </ModalHeader>
          <ModalBody>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            {/* <ButtonToggle onClick={(_)=> getData()}> Save Data </ButtonToggle> */}
          </ModalBody>
        </Container>
      </Modal>
      {/* <Link to="/user/edit"></Link> */}
      <Datatable
        dataset={users}
        totalPage={totalPage}
        hover
        onChange={handleTableChange}
        searchable
      >
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        <DatatableColumn field="name" sortable>
          Name
        </DatatableColumn>
        <DatatableColumn field="email" sortable>
          E-Mail Address
        </DatatableColumn>
        <DatatableColumn field="phone" sortable>
          Phone Number
        </DatatableColumn>
        <DatatableColumn field="address">Address</DatatableColumn>
        <DatatableColumn field="action">Action</DatatableColumn>
      </Datatable>
    </>
  );
}

export default CustomerList;
