import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import {
  refreshAccessToken,
  accessToken,
} from "../../../auth/AuthCheck";
import { Link } from "react-router-dom";
import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import NotificationForm from "../../../components/NotificationForm";
import useApi from "../../../hooks/useApi";
import ArticleAPI from "../../../api/article";

function ArticleList(props) {
  //API CALL 
  const getArticles = useApi(ArticleAPI.getArticles);
  const patchArticle = useApi(ArticleAPI.patchArticle);
  const deleteArticle = useApi(ArticleAPI.deleteArticle);

  const [articles, setArticles] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const toggleNotificationModal = () =>
    setNotificationModalShow(!notificationModalShow);
  const [selectedArticle, setSelectedArticle] = useState(null);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);

  function refreshTable() {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        return;
      }
      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page - 1}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];
      
      const { data } = await getArticles.request(params.join("&"), accessToken);

      const articleDatas = data.data.map((article) => ({
        ...article,
        "pages.title": (
          <p>
            <b>{article.card.title}</b>{" "}
            {`(${article.pages.length} page)`}
          </p>
        ),
        coverImage: (
          <img
            src={article.card.cardImageURL}
            alt={article.card.title}
            className="img-preview-thumbnail" />
        ),
        isPublished: article.isPublished ? (
          <Fragment>
            <span className="material-icons table-control--hover-hide">
              done
            </span>
            <Button
              className="table-control--hover-show"
              color="secondary"
              outline
              onClick={togglePublish(article._id, article.isPublished)}
            >
              Unpublish
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <span className="material-icons table-control--hover-hide">
              clear
            </span>
            <Button
              className="table-control--hover-show"
              color="success"
              outline
              onClick={togglePublish(article._id, article.isPublished)}
            >
              Publish
            </Button>
          </Fragment>
        ),
        isActive: article.isActive ? (
          <Fragment>
            <span className="material-icons table-control--hover-hide">
              done
            </span>
            <Button
              className="table-control--hover-show"
              color="secondary"
              outline
              onClick={toggleActive(article._id, article.isActive)}
            >
              Deactivate
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <span className="material-icons table-control--hover-hide">
              clear
            </span>
            <Button
              className="table-control--hover-show"
              color="success"
              outline
              onClick={toggleActive(article._id, article.isActive)}
            >
              Activate
            </Button>
          </Fragment>
        ),
        "admin.name": article.admin ? article.admin.name : "n/a",
        controls: (
          <Fragment>
            <Button
              tag={Link}
              to={`/article/edit/${article._id}`}
              color="primary"
              className="table-control--hover-show m-1"
            >
              <span className="material-icons align-middle">edit</span>
            </Button>{" "}
            <Button
              color="success"
              className="table-control--hover-show m-1"
              onClick={() => {
                setNotificationModalShow(true);
                setSelectedArticle(article);
              } }
            >
              <span className="material-icons align-middle">campaign</span>
            </Button>
            {"  "}
            <Button
              color="danger"
              className="table-control--hover-show m-1"
              outline
              onClick={handleDelete(article._id)}
            >
              <span className="material-icons align-middle">delete</span>
            </Button>
          </Fragment>
        ),
      }));
      setTotalPage(data.pagination.totalPage);
      setArticles(articleDatas);
    });
  }

  const togglePublish = (id, isPublished) => () => {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      try {
        await patchArticle.request(id, isPublished ? "unpublish" : "publish", accessToken)

        refreshTable();
      } catch (error) {
        console.error(error);
        alert("Something wrong happened, Error A-0P");
      }
    });
  };

  const toggleActive = (id, isActive) => () => {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      try {
        await patchArticle.request(id, isActive ? "deactivate" : "activate", accessToken);
        
        refreshTable();
      } catch (error) {
        console.error(error);
        alert("Something wrong happened, Error A-0A");
      }
    });
  };

  const handleDelete = (id) => () => {
    refreshAccessToken(async (err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }

      if (!window.confirm("Are you sure?")) return;
      try {
        await deleteArticle.request(id, accessToken);
        
        refreshTable();
      } catch (error) {
        console.error(err);
        alert("Something Wrong happened, Error A-0D");
        
      }
    })
  };

  return (
    <Fragment>
      {/* Broadcast Modal */}
      <Modal isOpen={notificationModalShow} toggle={toggleNotificationModal}>
        <ModalHeader toggle={toggleNotificationModal}>Notify User</ModalHeader>
        <ModalBody>
          <p className="text-muted">Notify user about this content</p>
          {selectedArticle && (
            <NotificationForm
              onSubmit={() => setNotificationModalShow(false)}
              article={selectedArticle}
            ></NotificationForm>
          )}
        </ModalBody>
      </Modal>
      <h3>Article List</h3>
      <Link to="/article/edit" className="btn btn-primary">
        Add new
      </Link>
      <Datatable
        dataset={articles}
        totalPage={totalPage}
        onChange={handleTableChange}
        searchable
        hover
      >
        <DatatableFilter
          type="select"
          field="isPublished"
          label="Published Status"
        >
          <option value="all">All</option>
          <option value={true}>Published</option>
          <option value={false}>Not published</option>
        </DatatableFilter>
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        <DatatableColumn field="coverImage">Cover Image</DatatableColumn>
        <DatatableColumn field="pages.title" sortable>
          Title
        </DatatableColumn>
        <DatatableColumn field="isPublished" sortable>
          Published
        </DatatableColumn>
        <DatatableColumn field="isActive" sortable>
          Active
        </DatatableColumn>
        <DatatableColumn field="admin.name">Author</DatatableColumn>
        <DatatableColumn field="controls">&nbsp;</DatatableColumn>
      </Datatable>
    </Fragment>
  );
}

export default ArticleList;
