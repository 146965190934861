import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import TagInput from "../../../components/TagInput.jsx";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck.js";

import useApi from "../../../hooks/useApi.js";
import GestureJktAPI from "../../../api/gestureJkt.js";
import GestureCategoryAPI from "../../../api/gestureCategory.js";

const previewableUrlPattern =
  /data:(image\/(jpeg|png|jpg)|video\/mp4)|http(s?):\/\/.+\.(jpg|jpeg|png|mp4)/;

function SingleGestureJktEditor(props) {
  //API CALL
  const getOneGesture = useApi(GestureJktAPI.getOneGesture);
  const updateGesture = useApi(GestureJktAPI.updateGesture);
  const getGestureCategories = useApi(GestureCategoryAPI.getGestureCategories);

  const { gestureId, onSubmit, onCancel } = props;
  const [gesture, setGesture] = useState({});
  const [labels, setLabels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [gestureCategories, setGestureCategories] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    refreshAccessToken(async (err, isSuccess) => {
      try {
        if (!isSuccess) {
          history.replace("/signin");
          return;
        }
        const { data } = await getOneGesture.request(
          gestureId.trim(),
          accessToken
        );

        data.labels = data.labels.map((label) => label.name);
        setLabels(data.labels);

        setSelectedCategory(data.category);
        setGesture(data);
      } catch (error) {
        console.error("ERR", error);
        alert("ERR");
      }
    });
  }, [gestureId, getOneGesture, history]);

  function submit(e) {
    e.preventDefault();
    setUploading(true);

    refreshAccessToken(async (err, isSuccess) => {
      try {
        if (!isSuccess) {
          history.replace("/signin");
          return;
        }

        const formData = {
          category: selectedCategory,
          labels: labels,
        };

        await updateGesture.request(formData, gesture._id.trim(), accessToken);

        onSubmit();
      } catch (err) {
        console.error(err);
      } finally {
        setUploading(false);
      }
    });
  }

  useEffect(() => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
        }
        alert("Error occured. ERR RT-01");
        return;
      }

      getGestureCategories
        .request("pageSize=999&all=true", accessToken)
        .then(({ data: { data } }) => setGestureCategories(data))
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR GC-02");
        });
    });
  }, [getGestureCategories]);

  return (
    <div className="gesture-editor-wrapper my-2">
      <form onSubmit={submit} onReset={onCancel} disabled={isUploading}>
        {(previewableUrlPattern.test(gesture.fileURL) &&
          ((/(\.|video\/)mp4/g.test(gesture.fileURL) && (
            <video
              src={gesture.fileURL}
              className="gesture-preview gesture-preview--video"
              controls
            ></video>
          )) || (
            <img
              src={gesture.fileURL}
              className="gesture-preview gesture-preview--image"
              alt=""
            />
          ))) || (
          <p className="text-center m-4">
            <i>preview not available for {gesture.fileURL}</i>
          </p>
        )}
        <FormGroup>
          <Label>File name</Label>
          <Input type="text" disabled value={gesture.fileURL} />
        </FormGroup>
        <FormGroup>
          <Label>Gesture Labels</Label>
          <TagInput
            value={labels || []}
            placeholder="Labels are comma separated (e.g. a,b,c)"
            delimiter={[",", ";"]}
            onChange={(tags) => {
              console.warn("tag", tags);
              setLabels(tags);
            }}
          ></TagInput>
        </FormGroup>
        <FormGroup>
          <Label>Gesture Category</Label>
          <Input
            type="select"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
          >
            {gestureCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        {gesture.isUploadError && (
          <div className="alert alert-danger">
            <h5>There's an error when uploading {gesture.gesture.name}</h5>
            <p>{gesture.uploadErrorMessage}</p>
          </div>
        )}
        {gesture.isUploaded && (
          <div className="alert alert-success">
            {gesture.gesture.name} successfully uploaded
          </div>
        )}
        <Row>
          <Col>
            <Button type="submit" color="primary" block>
              Save
            </Button>
          </Col>
          <Col>
            <Button type="reset" color="secondary" outline block>
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default SingleGestureJktEditor;
