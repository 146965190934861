import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { signIn } from "../auth/AuthCheck";

function AuthPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const login = () => {
    signIn(email, password, (error, isSuccess) => {
      if (isSuccess) {
        history.replace(from);
      } else {
        console.log(error.message);
        setAuthError(
          error && error.message ? error.message : "Sign in error occured"
        );
      }
    });
  };
  return (
    <Container>
      <Row className="justify-content-center align-items-center" id="auth-page">
        <Col sm="8" md="6" lg="5">
          <h1 className="mb-3">HearMe Admin</h1>
          {authError && <Alert color="danger">{authError}</Alert>}
          <Card>
            <CardBody>
              <Form id="login-form" onSubmit={login}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  ></Input>
                </FormGroup>
                <Button type="button" color="primary" onClick={login}>
                  Sign In
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AuthPage;
