import React, { Fragment, useState, useEffect } from "react";
import { Link, Route, useHistory } from "react-router-dom";

import Datatable, {
  DatatableColumn,
  DatatableFilter,
} from "../../../components/Datatable";
import { Button } from "reactstrap";
import { refreshAccessToken, accessToken } from "../../../auth/AuthCheck";
import HelpEditor from "./HelpEditor";
import useApi from "../../../hooks/useApi";
import HelpAPI from "../../../api/help";


function HelpList(props) {
    //API CALL
  const getHelps = useApi(HelpAPI.getHelps);
  const activatedHelp = useApi(HelpAPI.activatedHelp);
  const deleteHelp = useApi(HelpAPI.deleteHelp);
  const restoreHelp = useApi(HelpAPI.restoreHelp);

  const [helps, setHelps] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // datatable query props
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [orders, setOrders] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const history = useHistory();

  function handleTableChange({ page, pageSize, filters, orders, searchQuery }) {
    setPage(page);
    setPageSize(pageSize);
    setFilters(filters);
    setOrders(orders);
    setSearchQuery(searchQuery);
  }

  const toggleActive = (id, isActive) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        alert("Error occured, ERR RTOKEN-00");
        return;
      }
      return activatedHelp.request(id, isActive ? "deactivate" : "activate", accessToken)
        .then((_) => {
          refreshTable();
        })
        .catch((error) => {
          console.error(error);
          alert("Something wrong happened, Error AC-0A");
        });
    });
  };

  const handleDelete = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      
      if (!window.confirm("Are you sure?")) return;
      
      deleteHelp.request(id, accessToken)
        .then((_) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error AC-0D");
        });
    });
  };

  const handleRestore = (id) => () => {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) console.error(err);
        return;
      }
      
      restoreHelp.request(id, accessToken)
        .then((_) => {
          refreshTable();
        })
        .catch((err) => {
          console.error(err);
          alert("Something Wrong happened, Error AC-0R");
        });
    });
  };

  function refreshTable() {
    refreshAccessToken((err, isSuccess) => {
      if (!isSuccess) {
        if (err) {
          console.error(err);
          alert("Something goes wrong. Error GC-DT01");
        }
        return;
      }

      const filterQueries = Object.keys(filters)
        .map((key) => `filter[${key}]=${filters[key]}`)
        .join("&");
      const orderQueries = Object.keys(orders)
        .map((key) => `orders[${key}]=${orders[key] === "asc" ? 1 : -1}`)
        .join("&");
      const params = [
        `page=${page - 1}`,
        `pageSize=${pageSize}`,
        filterQueries,
        orderQueries,
        `q=${searchQuery || ""}`,
      ];
  
      return getHelps.request(params.join("&"), accessToken)
        .then((response) => {
          const helpData = response.data || [];
          const helpList = helpData.data.map((help) => ({
            ...help,
            name: help.deleted ? `${help.name} (deleted)` : help.name,
            isActive: help.isActive ? (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  done
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(help._id, help.isActive)}
                >
                  Deactivate
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  clear
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(help._id, help.isActive)}
                >
                  Activate
                </Button>
              </Fragment>
            ),
            isPublished: help.isPublished ? (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  done
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(help._id, help.isActive)}
                >
                  Unpublish
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <span className="material-icons table-control--hover-hide">
                  clear
                </span>
                <Button
                  color="secondary"
                  className="table-control--hover-show"
                  outline
                  onClick={toggleActive(help._id, help.isActive)}
                >
                  Publish
                </Button>
              </Fragment>
            ),
            controls: (
              <Fragment>
                <Link
                  to={`/help/edit/${help._id}`}
                  className="btn btn-primary table-control--hover-show"
                >
                  <span className="material-icons align-middle">edit</span>
                </Link>
                {help.deleted ? (
                  <Button
                    color="secondary"
                    className="table-control--hover-show"
                    onClick={handleRestore(help._id)}
                  >
                    <span className="material-icons align-middle">restore</span>
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    className="table-control--hover-show"
                    onClick={handleDelete(help._id)}
                  >
                    <span className="material-icons align-middle">delete</span>
                  </Button>
                )}
              </Fragment>
            ),
          }));
          setTotalPage(helpData.pagination.totalPage);
          setHelps(helpList);
        })
        .catch((err) => {
          console.error(err);
          alert("Error occured, ERR AC-DT02");
        });
    });
  }
  useEffect(refreshTable, [page, pageSize, filters, orders, searchQuery]);

  return (
    <Fragment>
      <h3>Help List</h3>
      <Route exact path="/help/edit/:id?">
        <Fragment>
          <hr className="my-2" />
          <HelpEditor
            onSubmit={() => {
              refreshTable();
              history.replace("/help");
            }}
          />
          <hr className="my-2" />
        </Fragment>
      </Route>
      <Route exact path="/help">
        <Link to="/help/edit" className="btn btn-primary">
          Add new
        </Link>
      </Route>
      <Datatable
        dataset={helps}
        totalPage={totalPage}
        onChange={handleTableChange}
        hover
      >
        <DatatableFilter type="select" field="isActive" label="Active Status">
          <option value="all">All</option>
          <option value={true}>Active</option>
          <option value={false}>Not Active</option>
        </DatatableFilter>
        <DatatableFilter
          type="select"
          field="isPublished"
          label="Published Status"
        >
          <option value="all">All</option>
          <option value={true}>Active</option>
          <option value={false}>Not Active</option>
        </DatatableFilter>
        <DatatableFilter
          type="select"
          field="deleted"
          default={false}
          label="Deleted Status"
        >
          <option value="all">All</option>
          <option value={true}>Deleted</option>
          <option value={false}>Not Deleted</option>
        </DatatableFilter>
        <DatatableColumn field="title" sortable>
          Category name
        </DatatableColumn>
        <DatatableColumn field="isActive" sortable>
          Active
        </DatatableColumn>
        <DatatableColumn field="isPublished" sortable>
          Published
        </DatatableColumn>
        <DatatableColumn field="controls">&nbsp;</DatatableColumn>
      </Datatable>
    </Fragment>
  );
}

export default HelpList;
